import React, { useEffect, useRef, useState } from "react";

import { useGlobalState } from "../components/global";
import {
  Alert,
  Button,
  Flex,
  Form,
  Image,
  Input,
  List,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import "./Order.css";
import shippingType from "./components/shipping_type";
import {
  FieldNumberOutlined,
  FieldTimeOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import orderStatus from "./components/order_status";
import ShippingForm from "./components/ShippingForm";
import GlobalWrap from "../components/globalWrap";
import OrderRefundForm from "./components/OrderRefundForm";
import OrderDetail from "./components/OrderDetail";
import ownerIcon from "../components/owner_icon";
import OwnerAvatar from "../components/OwnerAvatar";
import TableList from "./components/TableList";
import { useBlocker } from "react-router-dom";
import ServiceRemark from "./components/ServiceRemark/ServiceRemark";

function Order() {
  const [data, setData]: any = useState({});
  const [loading, setLoading] = useState(true);
  const { currentTenant, jsonRequest } = useGlobalState();
  // 搜索表单
  const [queryForm] = Form.useForm();
  // 发货单表单
  const [shippingForm] = Form.useForm();
  // 退款表单
  const [refundForm] = Form.useForm();

  // 当前页
  const [pageNumber, setPageNumber] = useState(1);
  // 当前页容量
  const [pageSize, setPageSize] = useState(12);

  const [ownerAvatarDict, setOwnerAvatarDict]: any = useState({});

  const tableFormRef = useRef<any>(null);

  useEffect(() => {
    handlePageTo(pageNumber, pageSize);
    return () => { };
  }, [currentTenant]);

  const [displayListMode, setDisplayListMode] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      displayListMode &&
      currentLocation.pathname !== nextLocation.pathname &&
      tableFormRef.current?.isOwnValue()
    );
  });

  // 跳转分页数据
  const handlePageTo = (
    pageNumber: number,
    pageSize: number,
    queryData: any = null,
  ) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    queryData = queryData || queryForm.getFieldsValue();
    // channel.push("page-to", { page, size })
    if (!currentTenant) return;

    return jsonRequest
      .post("/api/request", {
        path: "/v1/order/",
        method: "get",
        application_key: "zy:services::mall",
        tenant_meta: currentTenant,
        data: {
          ...queryData,
          shippingType: "",
          page: pageNumber,
          pageSize: pageSize,
        },
      })
      .then((resp) => resp.json())
      .then(async ({ data }: any) => {
        setLoading(false);
        if (!data) {
          setData({});
          return;
        }
        setData(data);
        const ownerIDs = data.data.map(({ ownerId: ownerID }: any) => ownerID);
        ownerIcon(currentTenant, jsonRequest, "Elixir.Acupoint.Model.User", ownerIDs)
          .then((resp) => resp.json())
          .then(({ data }) =>
            setOwnerAvatarDict({ ...ownerAvatarDict, ...data }),
          );
      });
  };

  // 加载订单详情
  const loadingOrderDetail = async (transactionId: any) => {
    const resp = await jsonRequest.post("/api/request", {
      path: `/v1/order/${transactionId}/detail`,
      method: "get",
      application_key: "zy:services::mall",
      tenant_meta: currentTenant,
      data: {},
    });
    const jsonData = await resp.json();
    return jsonData.data;
  };

  // 查看订单详情
  const handleOrderDetail = async (transactionId: string) => {
    const order = await loadingOrderDetail(transactionId);

    Modal.confirm({
      width: 1200,
      title: <span>订单详情 - {orderStatus(order.status)}</span>,
      content: (
        <GlobalWrap>
          <OrderDetail order={order} />
        </GlobalWrap>
      ),
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  const handleCancelOrder = async (transactionId: string) => {
    await jsonRequest
      .post("/api/request", {
        path: `/v1/order/${transactionId}/cancel`,
        method: "post",
        application_key: "zy:services::mall",
        tenant_meta: currentTenant,
        data: {
          transactionId,
          refundAmount: 0,
          refundType: 1,
        },
      })
      .then((res) => res.json());

    handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue());
    message.success("撤销订单成功");
  };

  // 处理发货
  const handleShipping = async (transactionId: string) => {
    const order = await loadingOrderDetail(transactionId);

    Modal.confirm({
      width: 1200,
      title: "发货",
      content: (
        <GlobalWrap>
          <ShippingForm form={shippingForm} order={order} />
        </GlobalWrap>
      ),
      onOk: async (close) => {
        let values = await shippingForm.validateFields();

        values = {
          ...values,
          orderId: order.id,
          consigneeAddress: order.address?.fullAddress,
          consigneeRealname: order.address?.fullname,
          consigneeTelephone: order.address?.phone,
        };

        if (!!order.shippingManifest) {
          const resp = await jsonRequest.post("/api/request", {
            path: `/v1/shippingManifest/${order.shippingManifest.id}`,
            method: "post",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: values,
          });
          await resp.json();
        } else {
          const resp = await jsonRequest.post("/api/request", {
            path: `/v1/shippingManifest/`,
            method: "post",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: values,
          });
          await resp.json();
        }

        shippingForm.resetFields();
        handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue());
        close();
        return false;
      },
      onCancel: (close) => {
        shippingForm.resetFields();
        close();
      },
    });
  };

  // 撤回发货
  const handleShippingRevoke = async (orderId: string) => {
    jsonRequest
      .post("/api/request", {
        path: `/v1/order/${orderId}/shippingManifest`,
        method: "delete",
        application_key: "zy:services::mall",
        tenant_meta: currentTenant,
        data: {},
      })
      .then((resp) => resp.json())
      .then((data) => {
        message.success("撤回发货成功");
        handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue());
      })
      .catch(() => {
        message.error("撤回失败");
      });
  };

  // 处理订单退款
  const handleOrderRefund = async (transactionId: any) => {
    const order = await loadingOrderDetail(transactionId);

    Modal.confirm({
      width: 600,
      title: "退款",
      content: (
        <GlobalWrap>
          <OrderRefundForm form={refundForm} order={order} />
        </GlobalWrap>
      ),
      onOk: async (close) => {
        let values = await refundForm.validateFields();
        await jsonRequest.post("/api/request", {
          path: `/v1/order/${order.id}/refund`,
          method: "post",
          application_key: "zy:services::mall",
          tenant_meta: currentTenant,
          data: {
            ...values,
            orderId: order.id,
            ownerType: order.ownerType,
            ownerId: order.ownerId,
            transactionld: order.transactionld,
          },
        });

        refundForm.resetFields();
        handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue());
        close();
        return false;
      },
      onCancel: (close) => {
        refundForm.resetFields();
        close();
      },
    });
  };

  // 订单操作按钮
  const orderOperations = (order: any) => {
    const refundButton = (
      <Button
        size="small"
        onClick={() => handleOrderRefund(order.transactionId)}
      >
        退款
      </Button>
    );
    const detailButton = (
      <Button
        size="small"
        type="primary"
        onClick={() => handleOrderDetail(order.transactionId)}
      >
        查看
      </Button>
    );
    const cancelButton = (
      <Tooltip title="用户消费积分后再退款会员，可撤销用户订单，且不退还积分">
        <Popconfirm
          title="确定撤掉订单吗？"
          okText="是"
          cancelText="否"
          onConfirm={() => handleCancelOrder(order.transactionId)}
        >
          <Button size="small" type="dashed">
            撤销
          </Button>
        </Popconfirm>
      </Tooltip>
    );

    switch (order.status) {
      // 待支付
      case 0:
        return <>{detailButton}</>;
      // 已支付
      case 10:
        return (
          <>
            <Button
              size="small"
              type="primary"
              onClick={() => handleShipping(order.transactionId)}
            >
              发货
            </Button>
            {refundButton}
            {detailButton}
            {cancelButton}
          </>
        );
      // 已发货
      case 20:
        return (
          <>
            <Button
              size="small"
              onClick={() => handleShipping(order.transactionId)}
            >
              修改发货单
            </Button>
            <Popover
              content={
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() => handleShippingRevoke(order.id)}
                >
                  确定
                </Button>
              }
            >
              <Button size="small">撤回发货</Button>
            </Popover>
            {detailButton}
            {cancelButton}
          </>
        );
      // 已完成
      case 30:
        return (
          <>
            <Popover
              content={
                <Alert
                  type="warning"
                  message="注意：此订单已完成"
                  showIcon
                ></Alert>
              }
            >
              {refundButton}
            </Popover>
            {detailButton}
          </>
        );
      // 部分退款
      case 40:
        return (
          <>
            {refundButton}
            {detailButton}
          </>
        );
      // 全额退款
      case 50:
        return <>{detailButton}</>;
      default:
        return <></>;
    }
  };

  const handleFilter = async (values: any) => {
    if (values.status !== 10 && displayListMode) {
      if (!tableFormRef.current?.isOwnValue()) {
        await handlePageTo(1, pageSize, values);
        setDisplayListMode(false);
        return;
      }
      Modal.confirm({
        title: <span>操作提醒</span>,
        content: <p>切换查询条件，将清空批量发货表单的内容</p>,
        async onOk() {
          await handlePageTo(1, pageSize, values);
          setDisplayListMode(false);
        },
      });
    } else {
      handlePageTo(1, pageSize, values);
    }
  };

  const handleSwitch = async () => {
    if (!displayListMode) {
      queryForm.setFieldValue("status", 10);
      await handlePageTo(1, pageSize);
      setDisplayListMode(!displayListMode);
      return;
    }
    if (!tableFormRef.current?.isOwnValue()) {
      setDisplayListMode(!displayListMode);
      return;
    }
    Modal.confirm({
      title: <span>操作提醒</span>,
      content: <p>切换到卡片显示，将清空表单内容</p>,
      onOk() {
        setDisplayListMode(!displayListMode);
      },
    });
  };

  const handleExport = () => {
    tableFormRef.current
      ?.handleSubmit(data.data)
      .then(async () => {
        await handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue());
        setDisplayListMode(false);
      })
      .catch((e: Error) => {
        console.log(e); // 表单校验错误
      });
  };

  return (
    <div className="page mall-order">
      <h1>订单列表</h1>

      <div className="content">
        <Flex gap="middle" vertical={false}>
          <Form
            form={queryForm}
            size="small"
            autoComplete="off"
            layout="inline"
            onFinish={handleFilter}
            style={{ flex: "1 1 auto" }}
            initialValues={{ status: null }}
          >
            <Form.Item
              name="transactionId"
              label="订单号"
              style={{ minWidth: 300 }}
              normalize={(v) => (v?.length === 0 ? null : v)}
            >
              <Input placeholder="只能搜订单号" allowClear />
            </Form.Item>
            <Form.Item
              name="ownerId"
              label="用户ID"
              style={{ minWidth: 300 }}
              normalize={(v) => (v?.length === 0 ? null : v)}
            >
              <Input placeholder="输入用户ID" allowClear />
            </Form.Item>
            <Form.Item name="status" label="订单状态">
              <Select style={{ minWidth: 100 }}>
                <Select.Option>全部</Select.Option>
                <Select.Option value={0}>待支付</Select.Option>
                <Select.Option value={10}>已支付</Select.Option>
                <Select.Option value={20}>已发货</Select.Option>
                <Select.Option value={30}>已完成</Select.Option>
                <Select.Option value={40}>部分退款</Select.Option>
                <Select.Option value={50}>全额退款</Select.Option>
                <Select.Option value={1}>已取消</Select.Option>
                <Select.Option value={2}>已超时</Select.Option>
                <Select.Option value={3}>已撤销</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="type" label="商品类型">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={0}>实物商品</Radio.Button>
                <Radio.Button value={1}>虚拟商品</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Button.Group>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button
                onClick={() => {
                  queryForm.resetFields([{ status: null }]);
                  handleFilter({});
                }}
                htmlType="reset"
              >
                清空
              </Button>
            </Button.Group>
          </Form>
          <Button
            style={{ flex: "0 0 auto" }}
            type="primary"
            size="small"
            onClick={handleSwitch}
          >
            {displayListMode ? "卡片展示" : "批量发货"}
          </Button>
          {displayListMode && (
            <Button
              style={{ flex: "0 0 auto" }}
              type="primary"
              size="small"
              onClick={handleExport}
              danger
            >
              发货提交
            </Button>
          )}
        </Flex>
        <br />
        {displayListMode ? (
          <TableList
            ref={tableFormRef}
            count={data.count}
            data={data.data}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handlePageTo={handlePageTo}
          />
        ) : (
          [
            <List
              dataSource={data.data}
              loading={loading}
              rowKey={(d) => d.transactionId}
              grid={{ gutter: 16, column: 4 }}
              itemLayout="vertical"
              size="large"
              renderItem={(item: any) => (
                <List.Item key={item.id} className="item-order">
                  <div className="content">
                    <div className="header">
                      {orderStatus(item.status)}
                      <span
                        className="transaction-id"
                        onClick={() =>
                          navigator.clipboard
                            .writeText(item.transactionId)
                            .then(() => {
                              message.success({
                                content: "已复制订单单号到剪切板中",
                                key: "exporting",
                                duration: 3,
                              });
                            })
                        }
                      >
                        <FieldNumberOutlined
                          style={{ color: "rgb(22, 119, 255)" }}
                        />
                        {item.transactionId}
                      </span>
                      <span style={{ color: "#eb2f96" }}>
                        <MoneyCollectOutlined /> {item.paymentAmount}
                      </span>
                    </div>
                    <OwnerAvatar
                      avatar={ownerAvatarDict[item.ownerId]?.avatar}
                      nickname={ownerAvatarDict[item.ownerId]?.nickname}
                      id={ownerAvatarDict[item.ownerId]?.id}
                    />
                    <div className="product-sku">
                      {item.extendInfo.map((detail: any) => (
                        <div className="sku-item" key={detail.id}>
                          <Image
                            className="img"
                            src={detail.productSku?.images?.[0].objName}
                            width={80}
                          />
                          <span className="productTitle">
                            {detail.productTitle}{" "}
                            <span style={{ color: "#aaa" }}>
                              x {detail.quantity}
                            </span>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div
                      className="buyer-message"
                      onClick={() =>
                        !!item.buyerMessage &&
                        Modal.info({
                          title: "买家留言",
                          content: item.buyerMessage,
                          centered: true,
                        })
                      }
                    >
                      用户留言：{item.buyerMessage || "无"}
                    </div>
                    <ServiceRemark
                      className="buyer-message"
                      currentTenant={currentTenant!}
                      remark={item.remark}
                      transactionId={item.transactionId}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      <Tag>{shippingType(item.shippingType)}</Tag>
                    </span>
                    <span style={{ color: "#aaa" }}>
                      <FieldTimeOutlined />{" "}
                      {dayjs
                        .tz(item.orderedAt)
                        .tz(dayjs.tz.guess())
                        .format("YYYY-MM-DD HH:mm")}
                    </span>
                  </div>
                  <Space
                    align="end"
                    style={{ width: "100%", justifyContent: "end" }}
                  >
                    {orderOperations(item)}
                  </Space>
                </List.Item>
              )}
            ></List>,
            <Pagination
              size="default"
              total={data.count}
              pageSize={pageSize}
              current={pageNumber}
              onChange={(page, size) => handlePageTo(page, size)}
            ></Pagination>,
          ]
        )}
      </div>
      <Modal
        title="操作提醒"
        open={blocker.state === "blocked"}
        onOk={() => blocker.proceed?.()}
        onCancel={() => blocker.reset?.()}
      >
        <p>切换到其他页面，将清空表单内容</p>
      </Modal>
    </div>
  );
}
export default Order;
