

import "./TestClient.css"
import { useGlobalState } from "../components/global"
import configFunc from "../config"
export default function TestClient() {
    const { user, currentTenant } = useGlobalState()


    return <div className="page customer-service test-client">
        <iframe src={`${configFunc.HttpEndpoint("/api/customer-service/test-client")}?token=${user.token}&tenant_meta=${currentTenant}`}>

        </iframe>
    </div>
}