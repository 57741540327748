import scss from "./index.module.css";
import { memo, useState } from "react";
import { Input } from "antd";
import { useGlobalState } from "../../../components/global";

const { TextArea } = Input;

type Props = {
  className?: string;
  currentTenant: string;
  transactionId: string;
  remark?: string;
};
export default memo(function ServiceRemark({ className, ...props }: Props) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.remark || "");
  const { jsonRequest } = useGlobalState()

  async function submit() {
    const val = value.trim();
    if (val) {
      await jsonRequest.post("/api/request", {
        path: `/v1/order/${props.transactionId}/${val}`,
        method: "put",
        application_key: "zy:services::mall",
        tenant_meta: props.currentTenant,
        data: {},
      });
    }
    setEdit(false);
  }

  function onChange(e: any) {
    const { value: inputValue } = e.target;
    setValue(inputValue);
  }

  return (
    <div
      onClick={() => setEdit(true)}
      className={[className, scss.remark].join(" ")}
    >
      <div className={scss.label}>客服留言：</div>
      {edit ? (
        <TextArea
          value={value}
          onChange={onChange}
          className={scss.input}
          rows={3}
          onBlur={submit}
          maxLength={80}
          onPressEnter={submit}
          count={{
            max: 80,
          }}
        />
      ) : (
        <div className={scss.input}>{value || "无"}</div>
      )}
    </div>
  );
});
