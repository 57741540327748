import { Outlet } from "react-router-dom"
import SideMenu from "./components/side_menu"
import { useGlobalState } from "../components/global"
import { useEffect } from "react"


function Operator(params: any) {

    const { navActive } = useGlobalState()

    useEffect(() => {
        navActive("OPERATOR")

    }, [])

    return <div className="module module-operator">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>

}

export default Operator