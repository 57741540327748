
import { Table, Progress } from "antd";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useGlobalState } from "../components/global";

function CoinPool() {

    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const { jsonRequest, currentTenant } = useGlobalState()

    useEffect(() => {
        handlePageTo(1, 10)
        return () => {
        }
    }, [currentTenant])

    const handlePageTo = (page_number: number, page_size: number) => {
        // channel.push("page-to", { page, size })
        if (!currentTenant) return;

        jsonRequest.post("/api/request/api/coin/pool", { method: "get", application_key: "zy:services::finance", tenant_meta: currentTenant, data: { page_number, page_size } })
            .then(resp => resp.json())
            .then(({ data }) => {
                setLoading(false)
                setData(data)
            })
    }


    return <div className="page coin-code-table">
        <h1>积分池</h1>

        <div className="content">
            <Table dataSource={data?.entries} loading={loading}
                scroll={{ y: document.documentElement.clientHeight - 350 }}
                rowKey={d => d.completed_at?.toString()}
                pagination={{
                    onChange: (page, size) => { handlePageTo(page, size) },
                    total: data.total_count,
                    current: data.page_number,
                    pageSize: data.page_size,
                    showQuickJumper: true
                }}>
                <Table.Column align="left" title="事件key" dataIndex="event_key" key="event_key" />
                <Table.Column align="left" title="事件名" dataIndex="name" key="name" />
                <Table.Column align="left" title="持有人类型" dataIndex="owner_type" key="owner_type" />
                <Table.Column align="left" title="持有人ID" dataIndex="owner_id" key="owner_id" />
                {/* <Table.Column align="center" title="用户" dataIndex="owner" key="owner" render={v => !!v && <UserAvatar nickname={v.nickname} size={40} avatar={v.avatar} id={v.id} />} /> */}
                <Table.Column align="left" title="进度" dataIndex="amount" key="amount" render={(v, item: any) => <Progress size={item.trigger_times > 5 ? "small" : "default"} steps={item.trigger_times} percent={Math.floor(item.times / item.trigger_times * 100)} />} />
                <Table.Column align="center" title="重置时间" dataIndex="reset_at" key="reset_at" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" title="完成时间" dataIndex="completed_at" key="completed" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" title="完成获得积分" dataIndex="completed_coin_amount" key="completed_coin_amount" />
            </Table>
        </div>
    </div>
}



export default CoinPool;