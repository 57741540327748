import { Button, Flex, Form, Input, Space, message } from "antd";
import { useEffect, useRef, useState } from "react";
import './CompareForm.css';
import html2canvas from "html2canvas";
import { useWatch } from "antd/lib/form/Form";
import RichTextEditor from "quill-react-commercial";
import { useGlobalState } from "../../../components/global";
import ContentImageUploader from "../../components/ContentImageUploader";

export default function CompareForm({ form, init, onOk, onCancel }: any) {
    const { jsonRequest, currentTenant } = useGlobalState()
    const quill: any = useRef();
    const tableRef: any = useRef();
    const selected = useWatch('selected', form)
    const [watermark, setWatermark]: any = useState({})
    const [loading, setLoading]: any = useState(false)

    const modules = {
        table: {},
        codeHighlight: true,
    }

    useEffect(() => {
        getWatermark();
        form.resetFields();
        form.setFieldValue('selected', null);
    }, [])

    const onEditorChange = () => {
        const selected = form.getFieldValue('selected');
        if (!selected) return;
        form.setFieldValue(selected, quill.current.getSemanticHTML())
    }

    const getQuill = (quillIns: any) => quill.current = quillIns;

    const getWatermark = () => {
        jsonRequest.post("/api/request", {
            path: `/v1/contents/medias/tenant/${currentTenant}/watermark`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        })
            .then((resp: any) => resp.json())
            .then(({ data }) => setWatermark(data))
    }

    const convertBase64UrlToBlob = (urlData: any) => {
        var bytes = window.atob(urlData.split(',')[1]);        //去掉url的头，并转换为byte
        //处理异常,将ascii码小于0的转换为大于0
        var ab = new ArrayBuffer(bytes.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/png' });
    }

    const takePicture = async () => {
        if (selected) {
            form.setFieldValue('selected', null);
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        const canvas: any = await html2canvas(tableRef.current, { useCORS: true, scale: 2 });
        const image = canvas.toDataURL('image/png');
        return await ContentImageUploader(jsonRequest, currentTenant, convertBase64UrlToBlob(image));
    }

    const clickGrid = async (name: any, content: any) => {
        if (selected && name.join('') === selected.join('')) { // 取消选中
            form.setFieldValue('selected', null);
        } else {
            form.setFieldValue('selected', name);
            await new Promise(resolve => setTimeout(resolve, 50));
            if (content) {
                // @ts-ignore
                document.querySelector('.compare-form .ql-editor').innerHTML = content;
            }
            await new Promise(resolve => setTimeout(resolve, 50));
            quill?.current?.focus();
            quill?.current.setSelection(quill?.current.getText().length);
        }
    }

    const CompareTableGrid = ({ content, name, style, ...others }: any) => {
        const isSelected = name && selected && name.join('') === selected.join('')
        return <td {...others} className={isSelected ? 'selected-grid' : ''}
            onClick={() => name && clickGrid(name, content)}>
            <div style={style} className='compare-table-grid'
                dangerouslySetInnerHTML={{ __html: content }} />
        </td>
    }

    const addColumn = () => {
        const { diff } = form.getFieldsValue();
        diff.push({ name: ' ', diff: ' ' })
        form.setFieldValue('diff', diff)
        form.validateFields(['diff'], { validateOnly: true });
    }

    const removeColumn = () => {
        const { diff } = form.getFieldsValue();
        if (diff.length === 2) {
            message.error('对比项不能少于两个');
            return;
        }
        const index = selected[selected?.length - 2];
        diff.splice(index, 1);

        form.setFieldValue('selected', null);
        form.setFieldValue('diff', diff)
        form.validateFields();
    }

    const CompareTable = () => {
        const { diff, equal } = form.getFieldsValue();
        // @ts-ignore
        return <div className='compare-table'>
            <table ref={tableRef} style={{ backgroundImage: `url(${watermark?.watermarkUrl})` }}>
                {/*<img src={watermark?.watermarkUrl} alt=""/>*/}
                <tbody>
                    <tr>
                        <CompareTableGrid colSpan={1} content='' style={{ textAlign: 'center' }} />
                        {diff?.map(({ name }: any, index: number) =>
                            <CompareTableGrid key={index} colSpan={1} style={{ textAlign: 'center' }} content={name}
                                name={['diff', index, 'name']} />)}
                    </tr>
                    <tr>
                        <CompareTableGrid colSpan={1} content='相同点' style={{ textAlign: 'center', color: '#45c1c4' }} />
                        <CompareTableGrid colSpan={diff?.length} content={equal} name={['equal']} />
                    </tr>
                    <tr>
                        <CompareTableGrid colSpan={1} content='不同点' style={{ textAlign: 'center', color: '#ee4143' }} />
                        {diff?.map(({ diff }: any, index: number) =>
                            <CompareTableGrid key={index} colSpan={1} content={diff} name={['diff', index, 'diff']} />)}
                    </tr>
                </tbody>
            </table>
        </div>
    }

    const onFormOk = async () => {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 500));
        try {
            const values = await form.validateFields();
            const img_url = await takePicture();
            onOk && onOk({ img_url, ...values })
        } catch (e) {
            setLoading(false)
        }
    }

    return <Form form={form} size='middle' labelCol={{ span: 2 }} wrapperCol={{ span: 20 }}
        initialValues={init} className='compare-form'>
        <Form.Item name='equal' hidden><Input /></Form.Item>
        <Form.Item name='diff' hidden><Input /></Form.Item>
        <Form.Item name='selected' hidden><Input /></Form.Item>
        <Form.Item name='title' label='标题' rules={[{ required: true, message: '不能为空' }]}>
            <Input />
        </Form.Item>
        <Flex justify='space-between'>
            <Space>
                <Button type='primary' onClick={addColumn}>新增列</Button>
                <Button type='primary' onClick={removeColumn}
                    disabled={!selected || selected.length < 2}>删除列</Button>
            </Space>
        </Flex>
        <Form.Item shouldUpdate noStyle>{() => <CompareTable />}</Form.Item>
        {selected &&
            <RichTextEditor modules={modules} i18n='zh' onChange={() => onEditorChange()}
                getQuill={getQuill} placeholder='请输入' />}
        <Flex justify='end'>
            <Space>
                <Button onClick={onCancel}>取消</Button>
                <Button type='primary' onClick={onFormOk} loading={loading}>确定</Button>
            </Space>
        </Flex>
    </Form>
}