import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline">
        <Menu.Item key="/user-library" icon={<UnorderedListOutlined />}>
            <Link to="/user-library">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>用户库</span>
                </div>
            </Link>
        </Menu.Item>
    </Menu>
}


export default SideMenu