import { Form, Input, Image } from "antd";
import UploadComponent from "../../components/UploadComponent";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { PlatformSelect } from "../components/platform_select";


declare var window: any;

export function IdentityForm({ form, ...props }: any) {
    const [fileList, setFileList]: any = useState([]);

    const { currentTenant } = useGlobalState()

    const handleUploadSuccess = (fileList: any) => {
        const images = fileList.map((e: any) => ({
            ...e,
            url: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));
        setFileList(images);
        // const url = 
        form.setFieldValue("logo", images[0].url)
    }
    return <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}  {...props} autoComplete="off">
        <Form.Item label="应用名" name="name" rules={[{ required: true, message: '不能为空' }]}>
            <Input />
        </Form.Item>
        <Form.Item label="appid" name="appid" rules={[{ required: true, message: '不能为空' }]} help="如：com.zy.{application name}">
            <Input />
        </Form.Item>
        <Form.Item label="系统 / 平台" name="platform" rules={[{ required: true, message: '不能为空' }]} >
            <PlatformSelect />
        </Form.Item>
        <Form.Item label="LOGO" >
            <UploadComponent path={[currentTenant, "identity"].join("/")}
                listType="picture-card"
                value={fileList}
                showUploadList={false}
                onChange={(fileList: any) => handleUploadSuccess(fileList)}>
                {!!fileList[0] ?
                    <Image src={fileList[0].url} preview={false} />
                    : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
            </UploadComponent>
        </Form.Item>
        <Form.Item label="logo路径" name="logo" rules={[{ required: true, message: '不能为空' }]}>
            <Input disabled />
        </Form.Item>
        <Form.Item label="备注" name="remark">
            <Input.TextArea autoSize style={{ minHeight: "100px" }} />
        </Form.Item>
    </Form>
}