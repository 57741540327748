import { message, Button, Form, ColorPicker, GetProp, ColorPickerProps } from "antd"
import { useState, useEffect } from "react"
import { useGlobalState } from "../../components/global"
import { ColorFactory } from "antd/es/color-picker/color"

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;
export default function ThemeForm() {
    const { jsonRequest, currentTenant } = useGlobalState()

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        handleLoadData(currentTenant!)
    }, [currentTenant])

    const handleLoadData = async (currentTenant: string) => {
        if (!currentTenant) return;

        setLoading(true);

        var response = await jsonRequest.post(`/api/request/api/tenant/detail`, { method: "get", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: {} })

        if (response.ok) {
            setLoading(false);
            var theme = response.body.data.theme || {};

            theme = {
                primary: dataTransToColor(theme.primary || {}),
                messageBackgroundColor: dataTransToColor(theme.messageBackgroundColor || {}),
                messageColor: dataTransToColor(theme.messageColor || {}),
                otherMessageColor: dataTransToColor(theme.otherMessageColor || {}),
                otherMessageBackgroundColor: dataTransToColor(theme.otherMessageBackgroundColor || {})
            }

            form.setFieldsValue(theme);
        }
    }

    var dataTransToColor = ({ alpha = 128, red = 128, green = 128, blue = 128 }: any): Color => {
        return new ColorFactory(`rgba(${red}, ${green}, ${blue}, ${alpha / 255})`)
    }

    var colorTransToData = (color: any) => {
        return {
            red: Math.floor(color.metaColor.r),
            green: Math.floor(color.metaColor.g),
            blue: Math.floor(color.metaColor.b),
            alpha: Math.floor(color.metaColor.a * 255)
        }
    }

    const handleSubmit = async (values: any) => {
        var resp = await jsonRequest.post(`/api/request/api/tenant/profile`, {
            method: "put", application_key: "zy:services::customer-service", tenant_meta: currentTenant,
            data: {
                theme: {
                    primary: colorTransToData(values.primary),
                    messageBackgroundColor: colorTransToData(values.messageBackgroundColor),
                    messageColor: colorTransToData(values.messageColor),
                    otherMessageColor: colorTransToData(values.otherMessageColor || {}),
                    otherMessageBackgroundColor: colorTransToData(values.otherMessageBackgroundColor || {})
                }
            }
        })
        if (resp.ok) {
            form.setFieldsValue(resp.body.data);
        } else {
            message.error("保存失败")
        }

    }
    return <div className="page coin-code-table">
        <h1>主题设置</h1>

        <div className="content" style={{ width: 600 }}>
            {
                !loading && <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}
                    autoComplete="off">
                    <Form.Item label="主题色" name="primary" rules={[{ required: true, message: '不能为空' }]} help="图标、色块等颜色">
                        <ColorPicker showText format="rgb" />
                    </Form.Item>
                    <Form.Item label="自己消息前景色" name="messageColor" rules={[{ required: true, message: '不能为空' }]}>
                        <ColorPicker showText format="rgb" />
                    </Form.Item>
                    <Form.Item label="自己消息背景色" name="messageBackgroundColor" rules={[{ required: true, message: '不能为空' }]}>
                        <ColorPicker showText format="rgb" />
                    </Form.Item>
                    <Form.Item label="他人消息前景色" name="otherMessageColor" rules={[{ required: true, message: '不能为空' }]}>
                        <ColorPicker showText format="rgb" />
                    </Form.Item>
                    <Form.Item label="他人消息背景色" name="otherMessageBackgroundColor" rules={[{ required: true, message: '不能为空' }]}>
                        <ColorPicker showText format="rgb" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">保存</Button>
                    </Form.Item>
                </Form>
            }
        </div>


    </div>
}