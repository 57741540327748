import { Button, Modal, Table, Tooltip } from "antd"
import { useState, useEffect } from "react"
import { useGlobalState } from "../components/global"
import { useLocation, useParams } from "react-router-dom"
import UTCTime from "../components/UTCTime"
import { PlusCircleTwoTone } from "@ant-design/icons"
import KeyGenerationForm from "./KeyGenerationForm"
import GlobalWrap from "../components/globalWrap"

export default function KeyList() {

    const [data, setData]: any = useState([])
    const [loading, setLoading] = useState(true)
    const { jsonRequest, currentTenant } = useGlobalState()

    const params = useParams()
    const { state } = useLocation()

    useEffect(() => {
        reload()
        return () => {
        }
    }, [currentTenant, params.application_key])

    const reload = () => {
        if (!currentTenant) return;

        jsonRequest.post("/api/request/api/key/keys", { method: "get", application_key: "zy:services::keys", tenant_meta: currentTenant, data: { application_key: params.application_key } })
            .then(resp => resp.json())
            .then(({ data: { data } }) => {
                setLoading(false)
                setData(data)
            })
    }

    const keyGenerationModal = () => {
        const { destroy: finish } = Modal.info({
            width: 1200,
            title: "密钥生成",
            content: <GlobalWrap><KeyGenerationForm application={state?.application} finish={() => finish()} /></GlobalWrap>,
            onOk: (close) => {
                close()
                return false
            },
            footer: null,
            keyboard: false,
            afterClose: reload
        })
    }

    return <div className="page key-table">
        <h1>{!!state && state.application.name}密钥列表 <Button icon={<PlusCircleTwoTone />} onClick={() => keyGenerationModal()} type="link">生成密钥</Button> </h1>

        <div className="content">
            <Table dataSource={data} loading={loading} pagination={false}>
                <Table.Column align="left" title="KEY" dataIndex="application_key" key="application_key" />
                <Table.Column align="left" title="名称" dataIndex="application_name" key="application_name" />
                <Table.Column align="left" title="kid" dataIndex="kid" key="kid" />
                <Table.Column align="left" width={150} title="jwk公钥" dataIndex="jwk" key="jwk" render={(text) =>
                    <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inline-block", width: 150 }}>
                        <Tooltip placement="leftBottom" title={JSON.stringify(text)}>{JSON.stringify(text)}</Tooltip>
                    </span>} />
                <Table.Column align="left" title="alg签名方法" width={300} dataIndex="alg" key="alg" render={(text) => JSON.stringify(text)} />
                <Table.Column align="center" width={200} title="有效时间" dataIndex="inserted_at" key="inserted_at" render={(text, item: any) => <span>{UTCTime(text)} - {UTCTime(item.expired_at) || "永久"}</span>} />
                <Table.Column align="center" width={100} title="状态" dataIndex="status" key="status" />
            </Table>
        </div>
    </div>
}