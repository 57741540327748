import { Button, Form, Input, message, Radio, Switch, Tooltip } from "antd";
import { useGlobalState } from "../../components/global";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import UploadComponent from "../../components/UploadComponent";

declare var window: any;
export default function ProfileForm() {
    const { jsonRequest, currentTenant } = useGlobalState()

    const [form] = Form.useForm()
    const [icon, setIcon] = useState<string>()

    useEffect(() => {
        handleLoadData(currentTenant!)
    }, [currentTenant])

    const handleLoadData = async (currentTenant: string) => {
        if (!currentTenant) return;

        var response = await jsonRequest.post(`/api/request/api/tenant/detail`, { method: "get", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: {} })

        setIcon(response.body.data?.icon)
        form.setFieldsValue(response.body.data);
    }

    const handleSubmit = async (values: any) => {
        var resp = await jsonRequest.post(`/api/request/api/tenant/profile`, { method: "put", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: { ...values, icon: icon } })
        if (resp.ok) {
            setIcon(resp.body?.data.icon)
            form.setFieldsValue(resp.body.data);
        } else {
            message.error("保存失败")
        }

    }

    const handleSetAvatar = ([{ url }, ..._]: any) => {
        setIcon(`${window.ATTACHMENT_URL}/api/oss/${url}`)
        form.setFieldsValue({ icon: icon })
    }

    return <div className="page coin-code-table">
        <h1>系统设置</h1>

        <div className="content" style={{ width: 600 }}>
            <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}
                autoComplete="off">
                <Form.Item label="名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="图标" name="icon" rules={[{ required: true, message: '不能为空' }]} help="点击或拖动上传">
                    <UploadComponent showUploadList={false} path={[currentTenant, "customer-service"].join("/")}
                        listType="picture-card"
                        onChange={(fileList: any) => handleSetAvatar(fileList)}>
                        <div>
                            {icon ? <img src={icon} alt="avatar" style={{ width: '100%' }} /> : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里或点击</div>
                            </div>}
                        </div>
                    </UploadComponent>
                    <div></div>
                </Form.Item>

                <Form.Item label="使用场景" name={["profile", "scene"]} rules={[{ required: true, message: '不能为空' }]} help="客服和IM模式在‘群组’行为有所不同，鼠标悬停查看差异">
                    <Radio.Group>
                        <Radio key="customer_service" value="customer_service">
                            <Tooltip title="客户端开启会话时会自动创建群并提示所有人">客服</Tooltip>
                        </Radio>
                        <Radio key="instant_message" value="instant_message">
                            <Tooltip title="IM模式类似常规的QQ、微信">IM</Tooltip>
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                {/* <Form.Item label="客户端是否APP Bar" name={["profile", "show_app_bar"]} valuePropName="checked">
                    <Switch />
                </Form.Item> */}
                <Form.Item label="客户端是否显示头像" name={["profile", "show_avatar"]} valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label="客户端是否显示昵称" name={["profile", "show_nickname"]} valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>

    </div>

}