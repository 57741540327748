const regexArray = [/file$/i, /^ignore.*/i];

const mediaHandlers: any = {
    'mind_map': (media: any) => {
        const {extData, mediaName} = media
        if (!extData) {
            media.extData = {mediaName}
        } else if (extData.nodeData) {
            extData.nodeData = handleNodeData(extData.nodeData)
        }
    },
}

const handleNodeData = ({id, topic, dangerouslySetInnerHTML, root, children}: any) => deleteEmptyField({
    id, topic, root,
    dangerouslySetInnerHTML: dangerouslySetInnerHTML || `<p>${topic}</p>`,
    children: children?.map((child: any) => handleNodeData(child)),
})

const handleMedias = (medias: any) => {
    if (!medias) return;
    medias.forEach((item: any) => {
        if (item.sons) handleMedias(item.sons);
        if (Object.keys(mediaHandlers).includes(item.mediaType))
            mediaHandlers[item.mediaType](item)
    })
}

const formatContentForm = (jsonObj: any) => {
    if (!jsonObj) return;
    if (Array.isArray(jsonObj)) {
        jsonObj.forEach((item) => formatContentForm(item))
    } else if (typeof jsonObj === 'object') {
        // 遍历 JSON 对象的每个字段
        Object.keys(jsonObj).forEach(key => {
            if (!jsonObj[key]) return;
            // 检查该字段是否匹配任意一个正则表达式
            const shouldRemove = regexArray.some(regex => regex.test(key));
            if (shouldRemove) {
                // 如果匹配，则删除该字段
                delete jsonObj[key];
            } else if (typeof jsonObj[key] === 'object' || Array.isArray(jsonObj[key])) {
                // 如果字段是一个嵌套的对象，则递归调用该函数
                formatContentForm(jsonObj[key]);
            }
        });
    }
}

const deleteEmptyField = (field: any) => {
    for (let key in field) {
        if (field[key] === undefined) {
            delete field[key];
        }
    }
    return field;
}

const handleContentValues = (values: any) => {
    formatContentForm(values);
    handleMedias(values?.medias);
}

export {handleContentValues}