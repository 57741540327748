import { Cascader, Form, Input, Select } from "antd";
import { useGlobalState } from "../../../../components/global";
import React, { Children, useEffect, useState } from "react";
import { useRequest } from "ahooks";

export default function FieldContentRefInput({ prefix, fullPrefix, form, field }: any) {
    const { extData } = field || {};
    const { currentTenant, jsonRequest } = useGlobalState();
    const item = form.getFieldValue(fullPrefix);
    const [contentOptions, setContentOptions]: any = useState([{ label: item?.contentName, value: item?.contentId }]);
    const [fieldOptions, setFieldOptions]: any = useState([]);

    const handleSearch = async (keyword: string) => {
        if (!keyword) return;
        return jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: extData?.systemCode, type: "content", keyword },
        })
          .then((resp: any) => resp.json())
          .then(({ data }: any) => {
              data && setContentOptions(data.map((e: any) => ({
                  label: e.fullName || e.contentName,
                  value: e.id,
              })));
          });
    };

    useEffect(() => {
        jsonRequest.post(`/api/request`, {
            path: `/v1/system-fields/tree`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: extData?.systemCode, field_type: "content" },
        })
          .then((resp: any) => resp.json())
          .then(({ data }: any) => {
              data && setFieldOptions(data);
          });
    }, []);

    const { run } = useRequest(handleSearch, {
        debounceWait: 500,
        manual: true,
    });

    const onSelectContent = ({ label, value }: any) => {
        form.setFieldValue([...fullPrefix, "contentId"], value);
        form.setFieldValue([...fullPrefix, "contentName"], label);
    };

    const onFieldContent = (value: any, selectOptions: any) => {
        const { id, fieldName } = selectOptions[selectOptions.length - 1];
        form.setFieldValue([...fullPrefix, "fieldId"], id);
        form.setFieldValue([...fullPrefix, "fieldFullId"], value);
        form.setFieldValue([...fullPrefix, "fieldName"], fieldName);
    };

    return <>
        <Form.Item name={[...prefix, "contentId"]} hidden initialValue={0}><Input /></Form.Item>
        <Form.Item name={[...prefix, "contentName"]} hidden initialValue=""><Input /></Form.Item>
        <Form.Item name={[...prefix, "fieldId"]} hidden initialValue={0}><Input /></Form.Item>
        <Form.Item name={[...prefix, "fieldName"]} hidden initialValue=""><Input /></Form.Item>
        <Form.Item name={[...prefix, "ignoreContentSelect"]} label="请选择内容"
                   initialValue={{ label: item?.contentName, value: item?.contentId }}>
            <Select
              showSearch
              onSelect={onSelectContent}
              placeholder={extData?.inputPrompt}
              defaultActiveFirstOption={false}
              labelInValue={true}
              suffixIcon={null}
              filterOption={false}
              onSearch={run}
              options={contentOptions}
            />
        </Form.Item>
        <Form.Item name={[...prefix, "ignoreFieldSelect"]} label="请选择字段" initialValue={[item?.fieldFullId]}>
            <Cascader
              onChange={onFieldContent}
              placeholder={extData?.inputPrompt}
              suffixIcon={null}
              options={fieldOptions}
              changeOnSelect
              allowClear={false}
              expandTrigger="hover"
              fieldNames={{ label: "fieldName", value: "id", children: "sons" }}
            />
        </Form.Item>
    </>;
}