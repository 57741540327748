import React, { useEffect } from "react";
import { Form, Input, TimePicker } from "antd";
import dayjs from "dayjs";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

export default function ViewPointForm({ form, detail }: any) {
    const { time, ...others } = detail;

    useEffect(() => {
        form.resetFields();
    }, []);
    
    return <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off"
                 initialValues={{ time: time ? dayjs.unix(time).utc() : dayjs("00:00:00", "HH:mm:ss"), ...others }}>
        <Form.Item name="id" hidden initialValue={!detail?.id && uuidv4()}>
            <Input disabled />
        </Form.Item>
        <Form.Item name="index" label="序号"><Input disabled /></Form.Item>
        <Form.Item name="time" label="时间" rules={[{ required: true, message: "不能为空" }]}>
            <TimePicker needConfirm={false} showNow={false} />
        </Form.Item>
        <Form.Item name="content" label="内容" rules={[{ required: true, message: "不能为空" }]}>
            <Input />
        </Form.Item>
    </Form>;
}