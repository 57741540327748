import { Button, Form, Input, Modal, Space } from "antd";
import PictureInput from "../PictureInput";
import FileInput from "../FileInput";
import React, { useEffect } from "react";
import OrderedMedia from "../../OrderedMedia";
import ResolutionTable from "./ResolutionTable";
import GlobalWrap from "../../../../../components/globalWrap";
import VideoUploadForm from "./VideoUploadForm";
import VideoInfo from "./VideoInfo";
import ViewPointTable from "./ViewPointTable";

export default function VideoInput({ form, prefix, fullPrefix, field, index, listPathName }: any) {
    const [videoForm] = Form.useForm();
    let videoModal: any;
    const { isRequired } = field;
    const resolutionName = [...fullPrefix, "multiresolution"];
    const viewPointName = [...fullPrefix, "viewPoint"];
    const operationName = [...fullPrefix.slice(0, -1), "operateList"];

    useEffect(() => {
        // init operation list
        const history = form.getFieldValue(operationName);
        if (!history) form.setFieldValue(operationName, []);
    }, []);

    const afterVideoUpload = async (isDefault: boolean, type: "add" | "edit") => {
        try {
            const values = await videoForm.validateFields();
            const { file, ...others } = values; // 去掉file参数
            const list = form.getFieldValue(resolutionName);
            if (list.length > 0 && isDefault) {
                list.find((e: any) => e.default).default = false;
            }
            if (type === "add") {
                if (list.length === 0 || isDefault)
                    setDefaultVideo(others);
                list.push({
                    default: list.length === 0 ? true : isDefault,
                    ...others,
                });
            } else {
                if (isDefault) { // 将其他视频的default设置为false
                    list.forEach((e: any) => e.default = false);
                    setDefaultVideo(others);
                }
                const item = list.find((e: any) => e.id === others.id);
                if (item.default) setDefaultVideo(others);
                list[list.indexOf(item)] = { default: item.default || isDefault, ...others };
            }
            pushOperation({ id: values.id, type: type === "add" ? "video_definition_add" : "video_definition_update" });
            form.setFieldValue(resolutionName, list);
            form.validateFields(resolutionName, { validateOnly: true });
            videoModal?.destroy();
        } catch (e) {
            console.error(e);
        }
    };

    const setDefaultVideo = (item: any) => {
        const {
            videoName, videoPath, hlsUrl, definition, definitionValue, realDefinitionValue,
            size, duration,
        } = item;
        const origin = form.getFieldValue(fullPrefix);
        form.setFieldValue(fullPrefix, {
            ...origin, videoName, videoPath, hlsUrl, definition, definitionValue, realDefinitionValue,
            size, duration, previewPictures: [], audioPath: "",
        });
        form.validateFields(resolutionName, { validateOnly: true });
    };

    const pushOperation = (operation: any) => {
        const history = form.getFieldValue(operationName);
        history.push(operation);
    };

    const handleVideoUpload = (detail: any) => {
        const list = form.getFieldValue(resolutionName);
        const rejectDefinitions = list?.map((e: any) => e.definitionValue);
        const passDetail = detail ? detail : { index: list.length + 1 };
        videoModal = Modal.confirm({
            width: 800,
            title: "添加视频",
            content: <GlobalWrap><VideoUploadForm form={videoForm} detail={passDetail}
                                                  rejectDefinitions={rejectDefinitions} /></GlobalWrap>,
            footer: ({ props }: any) => <Space>
                {props.children[0]}
                <Button type="primary" onClick={() => afterVideoUpload(true, detail ? "edit" : "add")}>
                    保存并设为默认
                </Button>
                <Button type="primary" onClick={() => afterVideoUpload(false, detail ? "edit" : "add")}>
                    保存
                </Button>
            </Space>,
            afterClose: () => videoForm.resetFields(),
        });
    };
    
    const InputBody = () => <>
        <Form.Item label={`视频标题`} name={[...prefix, "videoName"]}
                   rules={[{ required: isRequired, message: "不能为空" }]}>
            <Input placeholder="请输入视频标题" />
        </Form.Item>
        <Form.Item label={`视频描述`} name={[...prefix, "description"]}
                   rules={[{ required: isRequired, message: "不能为空" }]}>
            <Input placeholder="请输入视频描述" />
        </Form.Item>
        <PictureInput form={form} prefix={prefix} fullPrefix={fullPrefix}
                      field={field} labelPre="视频封面" />
        <Form.Item label="视频信息" name={[...prefix, "videoPath"]}>
            <VideoInfo form={form} name={fullPrefix} prefix={prefix} />
        </Form.Item>
        <Form.Item name={[...prefix, "size"]} initialValue={0} hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "duration"]} initialValue={0} hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "definition"]} initialValue="" hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "definitionValue"]} initialValue="" hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "realDefinitionValue"]} initialValue="" hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "hlsUrl"]} initialValue="" hidden><Input /></Form.Item>
        <FileInput form={form} labelPre="字幕" prefix={prefix} fullPrefix={fullPrefix} hideSize
                   type="other" field={field} namePre="subtitle" />
        <Form.Item name={[...prefix, "previewPictures"]} initialValue={[]} hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "audioPath"]} initialValue="" hidden><Input /></Form.Item>
        <Form.Item name={[...prefix, "multiresolution"]} initialValue={[]} wrapperCol={{ span: 24 }}>
            <ResolutionTable handleVideoUpload={handleVideoUpload} form={form} resolutionName={resolutionName}
                             setDefaultVideo={setDefaultVideo} pushOperation={pushOperation} />
        </Form.Item>
        <Form.Item name={[...prefix, "viewPoint"]} initialValue={[]} wrapperCol={{ span: 24 }}>
            <ViewPointTable form={form} name={viewPointName} fullPrefix={fullPrefix} />
        </Form.Item>
        <Form.Item name={[...prefix.slice(0, -1), "viewPointChange"]} initialValue={false} hidden><Input /></Form.Item>
    </>;

    return listPathName ? <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <InputBody />
    </OrderedMedia> : <InputBody />;
}