import './ProductCategory.css';
import {useState, useEffect} from "react"

import {Button, Form, Modal, Popconfirm, Table} from "antd"
import {useGlobalState} from "../../components/global"
import {DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusCircleTwoTone} from "@ant-design/icons"
import GlobalWrap from "../../components/globalWrap"
import ProductCategoryForm from "./components/ProductCategoryForm"

export default function ProductCategory() {
    const [data, setData]: any = useState(null)
    const [loading, setLoading] = useState(true)
    const { currentTenant, jsonRequest } = useGlobalState()
    // 搜索表单
    const [queryForm] = Form.useForm()
    // 分类表单
    const [categoryForm] = Form.useForm()

    useEffect(() => {
        handleLoad()
    }, [currentTenant])

    // 跳转分页数据
    const handleLoad = (queryData: any = null) => {
        queryData = queryData || queryForm.getFieldsValue()
        // channel.push("page-to", { page, size })
        if (!currentTenant) return;

        jsonRequest.post("/api/request", {
            path: `/v1/product/category/`,
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {}
        })
            .then((resp: any) => resp.json())
            .then(async ({data}: any) => {
                setLoading(false)
                setData(data)
            })
    }

    const constructCategoryTree = (treeData: any) => {
        return treeData.map((item: any) => ({
            title: item.categoryName,
            key: item.id,
            sort: item.sort,
            children: constructCategoryTree(item.child),
            data: item,
        }))
    }

    const deleteProductCategory = async (id: any) => {
        await jsonRequest.post("/api/request", {
            path: `/v1/product/category/${id}`,
            method: "delete",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {id},
        })
        handleLoad(queryForm.getFieldsValue())
    }

    const handleUpdateOrInsert = (category: any) => {
        Modal.confirm({
            width: 500,
            title: !!category.id ? `分类编辑 - ${category.categoryName}` : "分类新增",
            content: <GlobalWrap><ProductCategoryForm form={categoryForm} categoryTree={data}
                                                      category={category}/></GlobalWrap>,
            onOk: async (close) => {
                let values = await categoryForm.validateFields()

                if (!!category?.id)
                    await jsonRequest.post("/api/request", {
                        path: `/v1/product/category/${category.id}`,
                        method: "post",
                        application_key: "zy:services::mall",
                        tenant_meta: currentTenant,
                        data: {id: category.id, ...values, tags: values.tags?.join(",")}
                    })
                else
                    await jsonRequest.post("/api/request", {
                        path: `/v1/product/category/`,
                        method: "post",
                        application_key: "zy:services::mall",
                        tenant_meta: currentTenant,
                        data: {...values, tags: values.tags?.join(",")}
                    })

                categoryForm.resetFields();
                handleLoad(queryForm.getFieldsValue())
                close()
                return false
            },
            onCancel: (close) => {
                categoryForm.resetFields();
                close();
            }
        })
    }

    const columns = [
        {
            title: "商品类别",
            dataIndex: "title",
            key: 'key'
        },
        {
            title: "排序",
            dataIndex: "sort",
            key: 'sort'
        },
        {
            title: '操作',
            key: 'operation',
            render: (_: any, record: any) => <TreeBranch node={record}/>,
        }
    ];

    const TreeBranch = ({node}: any) => {
        const {key, data} = node;
        return (
            <div className='product-category-tree-branch-tools'>
                <Button type='link' size='small' onClick={() => handleUpdateOrInsert({pid: key})}>
                    <PlusCircleOutlined/>
                </Button>
                <Button type='link' size='small' onClick={() => handleUpdateOrInsert(data)}>
                    <EditOutlined/>
                </Button>
                <Popconfirm title="确定删除？" okText="是" cancelText="否"
                            onConfirm={() => deleteProductCategory(key)}>
                    <Button type='link' size='small' danger><DeleteOutlined/></Button>
                </Popconfirm>
            </div>
        )
    }
    
    return <div className="page mall-product">
        <h1 style={{display: "flex", alignItems: "center"}}>
            商品分类
            <Button onClick={() => handleUpdateOrInsert({})}
                    type="link" size="small" icon={<PlusCircleTwoTone/>}>添加分类</Button>
        </h1>

        <div className="content">
            {!!data && <Table columns={columns} dataSource={constructCategoryTree(data)}
                              pagination={false} expandable={{defaultExpandAllRows: true}}/>}
        </div>
    </div>
}