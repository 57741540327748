import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";

export default function RoleList() {
    const [tenants, setTenants]: any = useState([])
    const [roles, setRoles]: any = useState([])
    const [loading, setLoading] = useState(true)
    const { jsonRequest } = useGlobalState()
    const [applicationForm] = Form.useForm()
    const [selectedTenant, setSelectedTenant]: any = useState()

    useEffect(() => {
        handleLoad()

        return () => { }
    }, [])

    const handleLoad = async () => {
        const tenantResponseJson = (await jsonRequest.get("/api/tenant", {})).json()
        const data = (await tenantResponseJson).data
        setTenants(data)

        const selectedFirst = data[0]?.meta
        setSelectedTenant(selectedFirst)

        handleLoadRoles(selectedFirst)
    }

    const handleLoadRoles = async (tenant: any) => {
        const responseJson = (await jsonRequest.get(`/api/role/${tenant}`, {})).json()
        setRoles((await responseJson).data)
        setLoading(false)
    }

    const handleSelectTenant = (meta: string) => {
        setSelectedTenant(meta)
        handleLoadRoles(meta)
    }

    return <div className="page mall-order">
        <h1>角色列表</h1>

        <div className="content">
            <Select value={selectedTenant} onChange={(value: any) => handleSelectTenant(value)}>
                {
                    tenants.map((item: any) => {
                        return <Select.Option key={item.meta} value={item.meta}>{item.name}</Select.Option>
                    })
                }
            </Select>

            <p></p>
            <Table dataSource={roles} loading={loading} pagination={false}>
                <Table.Column align="left" title="应用服务名" dataIndex="name" key="name" />
                <Table.Column align="left" title="描述" dataIndex="description" key="description" />
                <Table.Column align="left" title="拥有权限" dataIndex="protocol" key="protocol" />
                <Table.Column align="center" title="操作" render={() => {
                    return <>
                        <Button type="link" icon={<EditOutlined />}>编辑</Button>
                    </>
                }} />
            </Table>
        </div>
    </div >
}