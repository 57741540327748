import { useEffect, useRef, useState } from "react"
import { useGlobalState } from "../../components/global"
import { JsonEditor } from 'jsoneditor-react18';
import 'jsoneditor-react18/es/editor.min.css';
import { Alert, Divider } from "antd";
import Ajv from 'ajv';
import ace from 'brace';
import debounce from "debounce"

import 'brace/mode/json';
import 'brace/theme/github';

const ajv = new Ajv({ allErrors: true, verbose: true });

export default function Signature() {

    const { jsonRequest, currentTenant } = useGlobalState()

    const [requestText, setRequestText]: any = useState(null)
    // json编辑器的关联
    const responseRef: any = useRef()
    const responseDataRef: any = useRef()
    const responseDataVerifiedRef: any = useRef()

    useEffect(() => {
        if (!!requestText)
            handleRequestSign(requestText)
    }, [currentTenant])


    const handleRequestSign = (text: any) => {
        if (text !== requestText)
            setRequestText(JSON.stringify(text))
        jsonRequest.post("/api/tenant/signature", { tenant_meta: currentTenant, text: JSON.stringify(text) })
            .then(resp => resp.json())
            .then(({ data }: any) => {
                // 需要单独设置json编辑器的值
                responseRef.current.jsonEditor.set({ "POST/PUT/PATCH": data?.signed, "GET/DELETE": `?${new URLSearchParams(data?.signed).toString()}` })
            })
    }

    const handleResponseVerified = (kid: any, payload: any) => {
        jsonRequest.post("/api/tenant/signature/verify", { tenant_meta: currentTenant, kid, payload })
            .then(resp => resp.json())
            .then(({ data }: any) => {
                // 需要单独设置json编辑器的值
                responseDataVerifiedRef.current.jsonEditor.set(data?.verified)
            })
    }

    const jsonEditorProps = { mode: "code", schema: null, theme: "ace/theme/github", ajv: ajv, ace: ace, htmlElementProps: { style: { height: document.documentElement.clientHeight - 350 } } }

    return <div>
        <h1>签名</h1>

        <Alert type="info" showIcon message="利用当前的租户签名，左边写请求的原始数据（JSON)， 右边会自动输出签名后的字符串。 切换租户时会自动重新签名。" ></Alert>
        <div className="content" style={{ marginTop: 20 }}>
            <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                <div style={{ flex: "1 1" }}>
                    <Divider>请求的真实数据</Divider>
                    <JsonEditor {...jsonEditorProps} mode="code" schema={null} value={{}} onChange={debounce((text) => handleRequestSign(text), 500)} />
                </div>

                <div style={{ flex: "1 1" }}>
                    <Divider>真实的请求体</Divider>
                    <JsonEditor {...jsonEditorProps} ref={responseRef} mode="code" schema={null} value={{}} />
                </div>
                <div style={{ flex: "1 1" }}>
                    <Divider>响应体</Divider>
                    <JsonEditor {...jsonEditorProps} ref={responseDataRef} mode="code" schema={null} value={{}} onChange={debounce((obj) => handleResponseVerified(obj?.kid, obj?.payload), 500)} />
                </div>
                <div style={{ flex: "1 1" }}>
                    <Divider>响应解析</Divider>
                    <JsonEditor {...jsonEditorProps} ref={responseDataVerifiedRef} mode="code" schema={null} value={{}} />
                </div>
            </div>
        </div>
    </div>
}