import { useEffect, useState } from "react";
import { Button, Cascader, Divider, Flex, Form, Input, message, Select, Space } from "antd";
import { useGlobalState } from "../../../components/global";
import { useWatch } from "antd/lib/form/Form";
import PictureInput from "./medias/PictureInput";
import RefSelector from "./RefSelector";
import MediaList from "./MediaList";
import ContentAnchor, { getMediaAnchorKey } from "./ContentAnchor/ContentAnchor";

export default function ContentForm({ form, detail, tenants, onOk, onCancel, type }: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const [cates, setCates]: any = useState([]);
    const [refs, setRefs]: any = useState([]);
    const ownerId = useWatch("ownerId", form);

    useEffect(() => {
        form.resetFields();
        initData();
    }, []);

    const initData = async () => {
        if (detail.id) {
            await getContentDetail();
            getContentMedias();
        }
        changeSystemCode(detail?.systemCode || tenants[0].systemCode);
    };

    const getContentDetail = async () => {
        await jsonRequest.post("/api/request", {
            path: `/v1/contents/${detail.id}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              console.log("content detail", data);
              const { id, cateIds, ...others } = data;
              form.setFieldsValue({ ownerId: id, ownerType: "content", extendCateIds: cateIds, ...others });
          });
    };

    const getContentMedias = async () => {
        await jsonRequest.post("/api/request", {
            path: `/v1/contents/medias/noneTemp`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: detail?.systemCode, ownerType: "content", ownerId: detail?.id },
        }).then((resp: any) => resp.json());

        jsonRequest.post("/api/request", {
            path: `/v1/contents/medias/content/${detail.id}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              console.log("content medias", data);
              if (data.length === 0) {
                  getTempMedias(detail.systemCode);
              } else {
                  form.setFieldsValue({ medias: data });
              }
          });
    };

    const getTempMedias = (systemCode: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/system-fields/temp/medias`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode, fieldType: "content", ownerId: detail?.id },
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              console.log("temp medias", data);
              form.setFieldsValue({ medias: data });
          });
    };

    const changeSystemCode = async (code: any) => {
        form.setFieldValue("systemCode", code);
        await getCateTrees(code);
        getGroupRefs(code);
        // 没有detail时使用模板
        !detail?.id && getTempMedias(code);
    };

    const getCateTrees = async (systemCode: any) => {
        await jsonRequest.post("/api/request", {
            path: `/v1/content/cates/group-trees`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode },
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              console.log("cate trees", data);
              setCates(data);
          });
    };

    const getGroupRefs = (systemCode: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/contents/group-refs`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode },
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              console.log("content refs", data);
              setRefs(data);
          });
    };

    const MultipleListIncludes = (list: any, node: any): boolean => {
        if (!list) return false;
        for (let i = 0; i < list.length; i++) {
            if (Array.isArray(list[i]) && MultipleListIncludes(list[i], node)) {
                return true;
            } else if (list[i] === node) {
                return true;
            }
        }
        return false;
    };

    const findPathsMatchingElements = (tree: any, elementsToMatch: any, currentPath: any = [], result: any = []) => {
        // 遍历当前节点的所有子节点
        for (let i = 0; i < tree?.length; i++) {
            const node = tree[i];
            // 将当前节点添加到当前路径
            const newPath = [...currentPath, node.id]; // 假设节点有'value'属性  

            // 如果节点有子节点，递归调用函数
            if (node.sons && node.sons.length > 0) {
                findPathsMatchingElements(node.sons, elementsToMatch, newPath, result);
            }

            if (elementsToMatch.includes(node.id) && !MultipleListIncludes(result, node.id)) {
                // 如果匹配，将路径添加到结果数组中  
                result.push(newPath);
            }
        }
        // 返回结果数组  
        return result;
    };

    const CategoryItems = () => {
        const ids = form.getFieldValue("extendCateIds") || [];
        if (ids.length > 0) {
            const initialIds = cates.map((e: any) => findPathsMatchingElements(e.trees, ids));
            form.setFieldValue("cateIds", initialIds);
            // 刷新表单，偶尔会出现设置cateIds后，表单中选项仍为空的情况
            form.validateFields(["cateIds"], { validateOnly: true });
        }
        return <>
            <Divider children="分类信息" />
            {cates.map((item: any, index: any) => (
              <Form.Item name={["cateIds", index]} label={item.groupName} key={item.groupName}
                         rules={[{ required: item.isRequired, message: "不能为空" }]}>
                  <Cascader showCheckedStrategy={Cascader.SHOW_CHILD} options={getCategoryChildren(item.trees)}
                            changeOnSelect multiple={item.isMultiple} showSearch />
              </Form.Item>
            ))}
        </>;
    };

    const RefItems = () => {
        const ids = form.getFieldValue("parentIds") || [];
        const initialIds = refs?.map(({ contentList }: any) => {
            const result = [];
            for (let i = 0; i < contentList?.length; i++) {
                if (ids.includes(contentList[i].id)) {
                    result.push(contentList[i].id);
                }
            }
            return result;
        });
        form.setFieldValue("parentIds", initialIds);
        return <>
            <Divider children="引用信息" />
            {refs.map((item: any, index: any) => (
              <Form.Item name={["parentIds", index]} label={item.fieldName} key={item.fieldKey}
                         rules={[{ required: item.isRequired, message: "不能为空" }]}>
                  <RefSelector content={item} />
              </Form.Item>
            ))}
        </>;
    };

    const getCategoryChildren = (cate: any) => {
        return cate?.map((item: any) => ({
            value: item.id,
            label: item.cateName,
            children: getCategoryChildren(item.sons),
        }));
    };

    const onNameChange = () => {
        const name = form.getFieldValue("contentName");
        if (!name) {
            form.setFieldValue("py", "");
            return;
        }
        jsonRequest.post("/api/request", {
            path: `/v1/contents/pinyin/${name}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
          .then(({ data }) => {
              form.setFieldValue("py", data?.pinyin);
          });
    };

    const handleOk = async () => {
        onOk && await onOk();
    };

    const scopeExpandMedia = async (medias: any) => {
        const sons = [];
        for (let i = 0; i < medias.length; i++) {
            const media = medias[i];
            window.dispatchEvent(new Event(`expand-all-media:#${getMediaAnchorKey(media)}`));
            if (media.sons?.length > 0) {
                sons.push(...media.sons);
            }
        }
        await new Promise(resolve => setTimeout(resolve, 200));
        if (sons.length > 0) scopeExpandMedia(sons);

    };

    const expandAllMedia = async () => {
        const { medias } = form.getFieldsValue();
        message.info('请稍后，数据量过大时会有些卡顿QuQ')
        await new Promise(resolve => setTimeout(resolve, 500));
        scopeExpandMedia(medias);
    };

    const FormList = ({ style }: any) => <div style={style}>
        <Form.Item label="ownerId" name="ownerId" hidden><Input disabled /></Form.Item>
        <Form.Item label="ownerType" name="ownerType" hidden><Input disabled /></Form.Item>
        <div id="base-info" />
        <Divider children="基础信息" />
        <Form.Item label="所属体系" name="systemCode" rules={[{ required: true, message: "不能为空" }]}>
            <Select onChange={(v: any) => changeSystemCode(v)} disabled={!!detail.systemCode}
                    options={tenants.map((e: any) => ({ value: e.systemCode, label: e.systemName }))} />
        </Form.Item>
        <Form.Item label="名称" name="contentName" rules={[{ required: true, message: "不能为空" }]}>
            <Input onChange={() => onNameChange()} />
        </Form.Item>
        <Form.Item label="别名" name="alias"><Input /></Form.Item>
        <Form.Item label="拼音" name="py"><Input /></Form.Item>
        <Form.Item label="序号" name="index" hidden><Input /></Form.Item>
        <Form.Item label="描述" name="description"><Input /></Form.Item>
        <PictureInput form={form} labelPre="封面" name="icon" />
        <Form.Item label="出处" name="provenance"><Input /></Form.Item>
        {cates.length > 0 && <CategoryItems />}
        {refs.length > 0 && <RefItems />}
        {ownerId && <MediaList form={form} type={type} />}
    </div>;

    return <>
        <Form form={form} size="middle" name="content-form" style={{ maxHeight: "70vh", overflowY: "scroll" }}
              labelCol={{ span: 2 }} wrapperCol={{ span: 21 }} className="medias-form" labelWrap={true} colon={false}
              initialValues={{ ownerId: detail?.id, ownerType: detail?.type }} scrollToFirstError={true}>
            <FormList />
            <ContentAnchor form={form} />
            {/*<Flex>*/}
            {/*<FormList style={{flex: 3, maxHeight: '80vh', overflowY: 'scroll'}}/>*/}
            {/*<div style={{width: '20px', height: '100%'}}/>*/}
            {/*<ContentFormPreview form={form} style={{flex: 2, maxHeight: '80vh', overflowY: 'scroll'}}/>*/}
            {/*</Flex>*/}
        </Form>
        <Flex justify="end" style={{ margin: "20px 20px 0" }}>
            <Space>
                <Button type="primary" onClick={expandAllMedia}>全部展开</Button>
                <Button onClick={onCancel}>取消</Button>
                <Button type="primary" onClick={handleOk}>保存</Button>
            </Space>
        </Flex>
    </>;
}