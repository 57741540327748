import SideMenu from "./components/side_menu";
import { Outlet } from "react-router-dom";
import { useGlobalState } from "../components/global";
import { useEffect } from "react";

export default function UserLibrary(params: any) {

    const { navActive } = useGlobalState();

    useEffect(() => {
        navActive("USER-LIBRARY");

    }, []);

    return <div className="module">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>;
}