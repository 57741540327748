import JsonRequest from './jsonRequest'
import { create } from 'zustand';

const user = JSON.parse(localStorage.getItem("sign-in-user") || "null")
const currentTenant = JSON.parse(localStorage.getItem("current-tenant") || "null")

export type globalState = {
    user: any,
    requestSignedPayload: string | null,
    navActiveKey: string | null,
    currentTenant: string | null
    jsonRequest: JsonRequest,
    initSignedPayload: (payload: string) => void
    navActive: (menuKey: string) => void
    signIn: (user: any) => void
    signOut: () => void
    setTenant: (currentTenant: string) => void
    setClient: (jsonRequest: JsonRequest) => void
}

export const useGlobalState = create<globalState>((set, get) => {
    return {
        // 当前用户
        user: user,
        // 请求的签名载荷
        requestSignedPayload: null,
        // 导航栏当前激活的key
        navActiveKey: null,
        // 当前的租户
        currentTenant: currentTenant,
        // json请求客户端
        jsonRequest: JsonRequest.tokenClient(user),
        // 初始化激活的payload
        initSignedPayload: (payload: string) => {
            set({ requestSignedPayload: payload })
        },
        // 设置当前活跃的导航项
        navActive: (menuKey: string) => {
            set({ navActiveKey: menuKey })
        },
        // 用户登录
        signIn: (user: any) => {
            set({ user })
        },

        // 用户退出登录
        signOut: () => {
            localStorage.setItem("sign-in-user", "null")
            localStorage.setItem("current-account", "null")
            set({ user: null })
        },

        // 设置当前的租户
        setTenant: (currentTenant: string) => {
            set({ currentTenant })
        },

        // 设置客户端
        setClient: (jsonRequest: JsonRequest) => {
            set({ jsonRequest })
        }
    }
})
