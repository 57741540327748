import { Form, Input } from "antd";
import { PlatformSelect } from "../components/platform_select";


export function ChannelForm({ form, ...props }: any) {

    return <div>
        <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}  {...props} autoComplete="off">
            <Form.Item label="渠道名" name="name" rules={[{ required: true, message: '不能为空' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="渠道标志" name="key" rules={[{ required: true, message: '不能为空' }]} help="如：AppStore">
                <Input />
            </Form.Item>
            <Form.Item label="系统 / 平台" name="platform" rules={[{ required: true, message: '不能为空' }]} >
                <PlatformSelect />
            </Form.Item>
            <Form.Item label="备注" name="remark">
                <Input.TextArea autoSize style={{ minHeight: "100px" }} />
            </Form.Item>
        </Form>
    </div>
}