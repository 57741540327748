import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "./components/side_menu";
import { useGlobalState } from "../components/global";

function Knowledge(params: any) {

    const { navActive } = useGlobalState()

    useEffect(() => {
        navActive("CONTENT")

    }, [])

    return <div className="module module-mall">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>

}

export default Knowledge