import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useGlobalState } from "../components/global";
import SideMenu from "./components/SideMenu";
export default function Shoppe(params: any) {
  const { navActive } = useGlobalState()

  useEffect(() => {
    navActive("SHOPPE");
  }, []);

  return (
    <div className="module module-mall">
      <div className="side-menu">
        <SideMenu />
      </div>
      <div className="content" style={{ overflowY: "scroll" }}>
        <Outlet />
      </div>
    </div>
  );
}
