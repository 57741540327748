import { Button, Divider, Form } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

export default function MultiInputGroup({ name, item, extData, addable, removable, divider, extend }: any) {
    const { min, max } = extData ?? {};

    return <Form.List
        name={name}
        rules={[
            {
                validator: async (_, names) => {
                    if (names && min && names.length < min) {
                        return Promise.reject(new Error(`至少添加${min}条数据`));
                    }
                },
            },
        ]}
    >
        {(fields, { add, remove }, { errors }) =>
            <>
                {fields.map((field, index) =>
                    <div key={field.key}>
                        <div key={field.key}>{item(index, field, add, remove)}</div>
                        {removable && fields.length > 1 && <Form.Item wrapperCol={{ offset: 5, span: 14 }}>
                          <Button type="dashed" onClick={() => remove(field.name)} block
                                  danger icon={<MinusCircleOutlined />}>
                            删除
                          </Button>
                        </Form.Item>}
                        {divider && index < fields.length - 1 && <Divider />}
                    </div>,
                )}
                {extend}
                {addable && <Form.Item wrapperCol={{ offset: 5, span: 14 }}>
                    {(!max || fields.length < max) &&
                      <Button block type="primary" ghost onClick={() => add()} icon={<PlusOutlined />}>
                        添加
                      </Button>}
                  <Form.ErrorList errors={errors} />
                </Form.Item>}
            </>
        }
    </Form.List>;
}