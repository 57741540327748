import OrderedMedia from "../OrderedMedia";
import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

export default function HistoryTranslateInput({ prefix, index, form, listPathName, changePrefix }: any) {

    const onChange = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), "change"], true);
    };

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item label="古文" name={[...prefix, "ancient"]} initialValue={""}>
            <TextArea autoSize={{ maxRows: 5 }} onChange={onChange} />
        </Form.Item>
        <Form.Item label="白话文" name={[...prefix, "modern"]} initialValue={""}>
            <TextArea autoSize={{ maxRows: 5 }} onChange={onChange} />
        </Form.Item>
    </OrderedMedia>;
}