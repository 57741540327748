import { Alert, Button, Collapse, Popconfirm, Space, Table, Tooltip } from "antd";
import { LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { bytesToSize, formatTime } from "../../../../../components/utils";

export default function ResolutionTable({
                                            value,
                                            handleVideoUpload,
                                            form,
                                            resolutionName,
                                            setDefaultVideo,
                                            pushOperation,
                                        }: any) {

    const setDefault = (index: any) => {
        const list = form.getFieldValue(resolutionName);
        if (list[index].default) return;

        list.forEach((e: any) => e.default = false);
        list[index].default = true;
        form.setFieldValue(resolutionName, list);
        setDefaultVideo(list[index]);
        // 添加操作记录
        pushOperation({ id: list[index].id, type: "video_definition_update" });
    };

    const exchangeIndex = (index1: any, index2: any) => {
        const list = form.getFieldValue(resolutionName);
        const node1 = list[index1];
        list[index1] = list[index2];
        list[index2] = node1;
        // 重设index
        list.forEach((e: any, i: any) => e.index = i + 1);
        form.setFieldValue(resolutionName, list);
        form.validateFields(resolutionName, { validateOnly: true });
        // 添加操作记录
        pushOperation({ id: list[index1].id, type: "video_definition_update" });
        pushOperation({ id: list[index2].id, type: "video_definition_update" });
    };

    const deleteItem = async (index: any) => {
        const list = form.getFieldValue(resolutionName);
        const item = list[index];
        list.splice(index, 1);
        if (item.default && list.length > 0) {
            list[0].default = true;
            setDefaultVideo(list[0]);
        }
        // 重设index
        list.forEach((e: any, i: any) => e.index = i + 1);
        // 添加操作记录
        pushOperation({ id: item.id, type: "video_definition_delete" });
        form.setFieldValue(resolutionName, list);
        form.validateFields(resolutionName, { validateOnly: true });
        await new Promise(resolve => setTimeout(resolve, 200));
        // 清空
        if (list.length === 0) {
            setDefaultVideo({
                videoName: "",
                videoPath: "",
                hlsUrl: "",
                definition: "",
                definitionValue: "",
                realDefinitionValue: "",
                size: 0,
                duration: 0,
            });
        }
    };

    const columns: any = [
        {
            title: "排序号",
            dataIndex: "index",
            key: "index",
            align: "center",
        },
        {
            title: "视频文件",
            dataIndex: "videoPath",
            key: "videoPath",
            align: "center",
            width: "30%",
            render: (_: any, record: any) =>
              <Alert type={record.default ? "success" : "info"}
                     onClick={() => window.open(record.videoPath, "_blank")}
                     message={<Button icon={<LinkOutlined />} type="link">{record.videoName}</Button>} />,
        },
        {
            title: "分辨率",
            dataIndex: "definitionValue",
            key: "definitionValue",
            align: "center",
            render: (_: any, record: any) => <span>{record.definition}({record.definitionValue})</span>,
        },
        {
            title: "真实分辨率",
            dataIndex: "realDefinitionValue",
            key: "realDefinitionValue",
            align: "center",
        },
        {
            title: "大小",
            dataIndex: "size",
            key: "size",
            align: "center",
            render: (_: any, record: any) => bytesToSize(record.size),
        },
        {
            title: "时长",
            dataIndex: "duration",
            key: "duration",
            align: "center",
            render: (_: any, record: any) => formatTime(record.duration),
        },
        {
            title: "操作",
            key: "operation",
            align: "center",
            render: (_: any, record: any, index: number) => <>
                <Button type="link" onClick={() => exchangeIndex(index, index - 1)}
                        disabled={index === 0}>上移</Button>
                <Button type="link" onClick={() => exchangeIndex(index, index + 1)}
                        disabled={value.length - 1 === index}>下移</Button>
                <Button type="link" onClick={() => setDefault(index)} disabled={record.default}>设为默认</Button>
                <Button type="link" onClick={() => handleVideoUpload(record)}>修改</Button>
                <Popconfirm title="确定删除吗？" onConfirm={() => deleteItem(index)}>
                    <Button type="link" danger>删除</Button>
                </Popconfirm>
            </>,
        },
    ];

    return <Collapse defaultActiveKey={[0]} items={[{
        key: 0,
        label: "多分辨率",
        children: <Table dataSource={value} columns={columns} />,
        extra: <Space>
            <Tooltip title="上传视频">
                <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handleVideoUpload();
                        }} />
            </Tooltip>
        </Space>,
    }]} />;
}