import { Button, Space } from "antd";
import { useRef } from "react";
import "./TableInput.css";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { HotTable, HotTableClass } from "@handsontable/react";

registerAllModules();

export default function TableInput({ value, onChange, field }: any) {
    const { title } = field?.extData || {};
    const childRef = useRef<HotTableClass>(null);

    const initialData = (): any => {
        if (value) return value;

        const arr = [];
        if (!title) {
            arr.push(["", ""]);
        } else {
            const titles = title.split(",");
            arr.push(titles.map((e: any) => e));
            arr.push(titles.map(() => ""));
        }
        onChange && onChange(arr);
        return arr;
    };

    const onTableChange = () => {
        const data = childRef.current?.hotInstance?.getData();
        onChange && onChange(data);
    };

    const addColumn = () => {
        const data: any = childRef.current?.hotInstance?.getData();
        data.forEach((e: any) => e.push(""));
        childRef.current?.hotInstance?.loadData(data);
        onTableChange();
    };

    const removeColumn = () => {
        const data: any = childRef.current?.hotInstance?.getData();
        const result: any = [];
        data.forEach((e: any) => result.push(e.slice(0, -1)));
        childRef.current?.hotInstance?.loadData(result);
        onTableChange();
    };

    const addRow = () => {
        const data: any = childRef.current?.hotInstance?.getData();
        data.push(data[0].map(() => ""));
        childRef.current?.hotInstance?.loadData(data);
        onTableChange();
    };

    const removeRow = () => {
        const data: any = childRef.current?.hotInstance?.getData();
        childRef.current?.hotInstance?.loadData(data.slice(0, -1));
        onTableChange();
    };

    return <Space direction="vertical" className="table-input">
        <Space>
            <Button onClick={addColumn}>添加列</Button>
            <Button onClick={removeColumn}>删除列</Button>
        </Space>
        <HotTable
          className="hot-table"
          ref={childRef}
          data={initialData()}
          manualRowResize={true}
          rowHeights={40}
          colWidths={200}
          width="100%"
          height="auto"
          cells={(row, column, prop) => {
              if (row === 0) return { className: "htCenter htMiddle htBold" };
              return { className: "htMiddle" };
          }}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
        />
        <Space>
            <Button onClick={addRow}>添加行</Button>
            <Button onClick={removeRow}>删除行</Button>
        </Space>
    </Space>;
}