import { Form, Input } from "antd";

const { TextArea } = Input;

export default function StringInput({
  prefix,
  name,
  field,
  form,
  fullPrefix,
  label,
  changePrefix = fullPrefix,
}: any) {
  const { extData } = field;
  const { inputPrompt } = extData ?? {};

  return (
    <Form.Item
      name={[...prefix, name || "description"]}
      label={label}
      wrapperCol={{ span: label ? 21 : 23 }}
      initialValue={""}
    >
      <TextArea
        placeholder={inputPrompt}
        autoSize={{ maxRows: 5 }}
        onChange={() => form.setFieldValue([...changePrefix.slice(0, -1), "change"], true)}
      />
    </Form.Item>
  );
}
