import {Select} from "antd";

export default function RefSelector({content, ...props}: any) {
    const {isMultiple, inputPrompt} = content;
    const refOptions = content.contentList?.map(({id, name}: any) => ({label: name, value: id}));

    return <Select
        {...props}
        placeholder={inputPrompt}
        mode={isMultiple ? "multiple" : undefined}
        showSearch={true}
        options={refOptions}
        filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
    />
}