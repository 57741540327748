import {Divider, Form, Input, InputNumber, Radio, Select, Spin, Switch, Tabs, TreeSelect} from "antd";
import {useEffect, useState} from "react";

import {useGlobalState} from "../../../components/global";
import constructCategoryTree from "./constructCategoryTree";
import '../Product.css';
import ProductSkuForm from "./ProductSkuForm";
import CustomQuill from "../../../components/CustomQuill";

declare var window: any;
const {Option} = Select;

export default function ProductForm({product, form, currencies}: any) {
    const [productCategoryData, setProductCategoryData]: any = useState([])
    const { currentTenant, jsonRequest } = useGlobalState()

    useEffect(() => {
        form.resetFields();

        if (product.id)
            getProductDetail(product.id);
        else
            setTabItems([getSkuTab(null, null, null, 0, false)])

        getCategory();
    }, [])

    const getProductDetail = (id: any) => {
        jsonRequest.post(`/api/request`, {
            path: `/v1/product/${id}`,
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {id}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => {
                form.setFieldsValue({
                    ...data,
                    productSku: data.productSkuDTO.map((e: any) => ({
                        ...e,
                        externalCallbackParamsStr: JSON.stringify(e.externalCallbackParams),
                    })),
                    tags: (!!data.tags && data.tags.length > 0) ? data.tags.split(",") : [],
                })
                setTabsFromSkus(data.productSkuDTO);
            })
    }

    const setTabsFromSkus = (skus: any) => {
        const tabs = [];
        for (let i = 0; i < skus.length; i++) {
            const {id, name} = skus[i];
            tabs.push(getSkuTab(id, id, name, i, skus.length > 1))
        }
        setTabItems(tabs)
    }

    const getSkuTab = (id: any, uId: any, label: any, key: any, closable: any) => ({
        id: id,
        uId: uId || (new Date().getTime().toString() + key), // 生成临时的uId，标志tab的唯一性
        label: label || `Sku ${key + 1}`,
        key: key,
        closable: closable,
        children: <ProductSkuForm form={form} tabKey={key} uId={uId}/>,
    })

    const getCategory = () => {
        jsonRequest.post("/api/request", {
            path: `/v1/product/category/`,
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {}
        })
            .then((resp: any) => resp.json())
            .then(async ({data}: any) => {
                setProductCategoryData(data)
            })
    }

    const [activeKey, setActiveKey]: any = useState(0);
    const [tabItems, setTabItems]: any = useState([])

    const addTab = () => {
        const newKey = tabItems.length;
        const newTabs = [...tabItems, getSkuTab(null, null, null, newKey, true)]
        newTabs.forEach((e: any) => {
            e.closable = newTabs.length > 1;
        })
        setTabItems(newTabs);
        setActiveKey(newKey);
    }

    const removeTab = (key: any) => {
        const newItems = tabItems.filter((item: any) => item.key !== key);
        const newTabs = [];
        for (let i = 0; i < newItems.length; i++) {
            const {id, uId, label} = newItems[i];
            newTabs.push(getSkuTab(id, uId, label, i, newItems.length > 1))
        }

        // 删除对应数据
        const oldSkus = form.getFieldValue('productSku')
        const newSkus = oldSkus.filter((_: any, index: any) => index !== key)
        form.setFieldValue('productSku', newSkus)

        // 记录deleteIds
        if (tabItems[key].id) {
            const deletedIds = form.getFieldValue('deleteIds') || []
            form.setFieldValue('deleteIds', [...deletedIds, tabItems[key].id])
        }

        // 设置activeKey
        const oldActive = tabItems[activeKey]
        const newActive = newTabs.find((e: any) => e.uId === oldActive.uId)
        const newActiveKey = newActive ? newTabs.indexOf(newActive) : newTabs.length - 1;

        setTabItems(newTabs);
        setActiveKey(newActiveKey);
    }

    const onTabsEdit = (targetKey: any, action: any) => {
        if (action === 'add') {
            addTab();
        } else {
            removeTab(targetKey);
        }
    };

    return <div className="mall-product-form">
        <Form form={form} size="small" name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}}
              autoComplete="off">
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{flex: 2}}>
                    <Divider>商品基础详情</Divider>
                    <Form.Item label="商品ID" name="id" style={{display: "none"}}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label="删除SkuIds" name="deleteIds" style={{display: "none"}}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label="商品名" name="productName" rules={[{required: true, message: '不能为空'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="商品分类" name="categoryId" rules={[{required: true, message: '不能为空'}]}>
                        <TreeSelect treeDefaultExpandAll treeData={constructCategoryTree(productCategoryData)}/>
                    </Form.Item>
                    <Form.Item label="是否需要回调" name="type" initialValue={0}
                               rules={[{required: true, message: "不能为空"}]}>
                        <Radio.Group buttonStyle="solid" options={[{label: '否', value: 0}, {label: '是', value: 1}]}/>
                    </Form.Item>
                    <Form.Item label="货币类型" name="currencyMeta" rules={[{required: true, message: "不能为空"}]}
                               initialValue={{currencyMeta: currencies.find((e: any) => e.default)?.meta}}>
                        <Select>
                            {currencies.map((item: any) => (
                                <Option key={item.meta} value={item.meta}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="商品排序" name="sort" initialValue={0}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item label="商品标签" name="tags">
                        <Select mode="tags"/>
                    </Form.Item>
                    <Form.Item label="是否上架" name="sale" valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    <Form.Item label="商品简介" name="summary" rules={[{required: true, message: '不能为空'}]}>
                        <Input.TextArea autoSize/>
                    </Form.Item>
                </div>
                <div style={{flex: 2}}>
                    <Divider>商品SKU</Divider>
                    {tabItems.length > 0 ?
                        <Tabs type="editable-card" onEdit={onTabsEdit} items={tabItems} activeKey={activeKey}
                              onChange={(v) => setActiveKey(v)}/> :
                        <Spin tip="Loading" size="large">
                            <div style={{
                                padding: 50,
                                background: 'rgba(0, 0, 0, 0.05)',
                                borderRadius: 4,
                            }}/>
                        </Spin>
                    }
                </div>
            </div>

            <div>
                <Divider><span className="product-detail-rich-title">商品详情</span></Divider>
            </div>
            <div>
                <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}} label="" name="content"
                           rules={[{required: true, message: '不能为空'}]} style={{flex: 1}}>
                    <CustomQuill/>
                </Form.Item>
            </div>
        </Form>
    </div>
}