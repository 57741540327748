import { AndroidOutlined, AppleOutlined, HarmonyOSOutlined, WindowsOutlined, ChromeOutlined, WechatOutlined } from "@ant-design/icons";
import { Select } from "antd";

export function PlatformSelect(props: any) {
    return <Select {...props}>
        <Select.Option value="android"><AndroidOutlined /> 安卓</Select.Option>
        <Select.Option value="ios"><AppleOutlined /> iOS / ipadOS</Select.Option>
        <Select.Option value="harmonyos"><HarmonyOSOutlined /> 鸿蒙</Select.Option>
        <Select.Option value="windows"><WindowsOutlined /> Windows</Select.Option>
        <Select.Option value="macos"><AppleOutlined /> macOS</Select.Option>
        <Select.Option value="web"><ChromeOutlined /> Web</Select.Option>
        <Select.Option value="wechat_navigator"><WechatOutlined /> 微信浏览器</Select.Option>
        <Select.Option value="wechat_miniprogram"><WechatOutlined /> 微信小程序</Select.Option>
    </Select>
}