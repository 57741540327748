
import {useGlobalState} from "../../components/global";
import {Button, Form, message, Modal, Select, Tag} from "antd";
import {useEffect, useRef, useState} from "react";
import {ActionType, ProTable} from "@ant-design/pro-components";
import GlobalWrap from "../../components/globalWrap";
import {PlusOutlined} from "@ant-design/icons";
import FieldTempForm from "./components/FieldTempForm";

export default function FieldTemp(params: any) {
    
    const { user, navActiveKey, jsonRequest, currentTenant, signOut, setTenant } = useGlobalState()
    const [form] = Form.useForm();
    const tableRef = useRef<ActionType>();

    const [tenants, setTenants] = useState<any>([]);
    const [fieldTypes, setFieldTypes] = useState<any>([]);
    const [mediaTypes, setMediaTypes] = useState<any>([]);

    useEffect(() => {
        getTenants();
        getFieldTypes();
        getMediaTypes();
    }, [currentTenant]);

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setTenants(data)
            })
    }

    const getFieldTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'field_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setFieldTypes(data)
            })
    }

    const getMediaTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'media_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setMediaTypes(data)
            })
    }

    const columns: any[] = [
        {
            title: '排序号',
            dataIndex: 'index',
            search: false,
            align: 'center',
        },
        {
            title: '字段名称',
            dataIndex: 'fieldName',
            search: false,
            align: 'center',
        },
        {
            title: '字段key',
            dataIndex: 'fieldKey',
            search: false,
            copyable: true,
            align: 'center',
        },
        {
            title: '上级字段key',
            dataIndex: 'parentFieldKey',
            search: false,
            copyable: true,
            align: 'center',
        },
        {
            title: '所属体系',
            dataIndex: 'systemCode',
            align: 'center',
            hideInTable: true,
            renderFormItem: () => <Select allowClear
                                          options={tenants.map((e: any) => ({
                                              value: e.systemCode,
                                              label: e.systemName
                                          }))}/>
        },
        {
            title: '字段类型',
            dataIndex: 'fieldType',
            align: 'center',
            renderFormItem: () => <Select allowClear
                                          options={fieldTypes.map((e: any) => ({
                                              value: e.enumKey,
                                              label: e.enumName
                                          }))}/>,
            render: (_: any, record: any) =>
                <span>{fieldTypes.find((e: any) => e.enumKey === record.fieldType)?.enumName || '-'}</span>
        },
        {
            title: '媒体类型',
            dataIndex: 'mediaType',
            align: 'center',
            renderFormItem: () => <Select allowClear
                                          options={mediaTypes.map((e: any) => ({
                                              value: e.enumKey,
                                              label: e.enumName
                                          }))}/>,
            render: (_: any, record: any) =>
                <span>{mediaTypes.find((e: any) => e.enumKey === record.mediaType)?.enumName || '-'}</span>
        },
        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            search: false,
            render: (_: any, record: any) => <Tag
                color={record.state ? 'success' : 'error'}>{record.state ? '启用' : '禁用'}</Tag>,
        },
        {
            title: '关键词',
            dataIndex: 'keyword',
            hideInTable: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            search: false,
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'option',
            search: false,
            align: 'center',
            width: 200,
            render: (_: any, record: any) => <>
                <Button type="link" onClick={() => handleAddOrEdit(record)}>修改</Button>
                <Button type="link" danger={record.state}
                        onClick={() => handleState(record)}>{record.state ? "禁用" : "启用"}</Button>
            </>,
        }
    ];

    const handleState = async ({id, state}: any) => {
        await jsonRequest.post("/api/request", {
            path: `/v1/system-fields/temp/state`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {id, state: !state},
        }).then(resp => resp.json())

        // @ts-ignore
        tableRef.current.reload();
        message.success(`操作成功`)
    }

    const handleAddOrEdit = async (detail: any) => {
        Modal.confirm({
            width: 1000,
            title: <span>字段模板编辑</span>,
            content: <GlobalWrap>
                <FieldTempForm form={form} tenants={tenants} mediaTypes={mediaTypes} fieldTypes={fieldTypes}
                               detail={detail}/>
            </GlobalWrap>,
            onOk: (close) => {
                form.validateFields()
                    .then(async (values) => {
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/system-fields/temp`,
                            method: values.id ? "put" : "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: values,
                        }).then(resp => resp.json())
                        if (!data.error) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`${detail?.id ? '编辑' : '新增'}成功`)
                            // close();
                        } else {
                            message.error(data.error);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            },
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const {current} = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/system-fields/temp/paged",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {...params, pageNumber: current},
        }).then((resp: any) => resp.json())
        const {data} = result;
        return {data: data.list, success: result.code === 0, total: data.total};
    }

    return (
        <>
            <h1>输入模板维护</h1>
            <ProTable
                rowKey="id"
                actionRef={tableRef}
                cardBordered
                columns={columns}
                request={request}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                search={{span: 6, defaultCollapsed: false}}
                toolBarRender={() => [<Button
                    key="button"
                    icon={<PlusOutlined/>}
                    onClick={() => handleAddOrEdit({})}
                    type="primary"
                >
                    新增
                </Button>]}
            />
        </>
    )
}