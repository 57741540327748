import { useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
import './EditableCell.css';
import { useGlobalState } from "../../../components/global";

export default function EditableCell({ id, index, afterChange }: any) {
    const { jsonRequest, currentTenant } = useGlobalState()
    const [editing, setEditing]: any = useState(false);
    const inputRef: any = useRef(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const save = async () => {
        const { index } = await form.validateFields();
        jsonRequest.post(`/api/request`, {
            path: `/v1/contents/update/index`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { id, index }
        })
            .then((resp: any) => resp.json())
            .then(() => afterChange && afterChange())
        toggleEdit();
    };

    if (editing) {
        return <div className='contents-editable-cell-td'>
            <Form form={form} size='small'>
                <Form.Item name='index' initialValue={index} noStyle>
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            </Form>
        </div>
    }

    return <div className='contents-editable-cell-td'>
        <div className='contents-editable-cell' onClick={toggleEdit}>{index}</div>
    </div>
}