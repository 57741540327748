import { Button, Form, Input } from "antd";
import KnowledgeUploader from "../../../components/KnowledgeUploader";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useGlobalState } from "../../../../components/global";
import { bytesToSize } from "../../../../components/utils";
import { useWatch } from "antd/lib/form/Form";

export default function FileInput({
    form,
    labelPre,
    prefix = [],
    fullPrefix = [],
    namePre,
    type,
    field,
    hideSize,
    pathName,
    onChange,
    accept,
}: any) {
    const { isRequired } = field || {};
    const { currentTenant } = useGlobalState()

    const pathInputName = pathName ? pathName : namePre ? `${namePre}Path` : "path";
    const sizeInputName = namePre ? `${namePre}Size` : "size";
    const fileInputName = namePre ? `${namePre}File` : "file";
    const fileList = useWatch([...fullPrefix, fileInputName], form);

    useEffect(() => {
        const path = form.getFieldValue([...fullPrefix, pathInputName]);
        const file = form.getFieldValue([...fullPrefix, fileInputName]);

        if (file || !path) return;

        const fileName = path.substring(path.lastIndexOf("/") + 1);
        const list: any = [{ name: fileName, url: path, uid: -(new Date()).getTime(), status: "done" }];
        form.setFieldValue([...fullPrefix, fileInputName], list);
    }, []);

    const handleFileChange = (fileList: any) => {
        const [file] = fileList;
        form.setFieldValue([...fullPrefix, pathInputName], file?.url || "");
        form.setFieldValue([...fullPrefix, sizeInputName], file?.size || "");
        file?.status === "done" && onChange && onChange(file);
    };

    return <>
        <Form.Item label={`${labelPre}文件`} rules={[{ required: isRequired, message: "不能为空" }]}
            name={[...prefix, fileInputName]}>
            <KnowledgeUploader path={[currentTenant, "product"].join("/")} type={type} maxCount={1}
                onChange={handleFileChange} accept={accept}>
                {((fileList || []).length === 0) &&
                    <Button icon={<UploadOutlined />}>点击上传</Button>}
            </KnowledgeUploader>
        </Form.Item>
        <Form.Item hidden={hideSize} dependencies={[[...prefix, sizeInputName]]} label={`${labelPre}文件大小`}>
            {() =>
                <span>{bytesToSize(form.getFieldValue([...fullPrefix, sizeInputName]) || 0)}</span>}
        </Form.Item>
        <Form.Item hidden label={`${labelPre}文件大小`} name={[...prefix, sizeInputName]}>
            <Input />
        </Form.Item>
        <Form.Item hidden label={`${labelPre}文件地址`} name={[...prefix, pathInputName]}>
            <Input />
        </Form.Item>
    </>;
}