import { Col, Form, Input, InputNumber, Row, Select } from "antd";

const { TextArea } = Input;

export default function FieldMediaTemp({ mediaType, tenants }: any) {
  const InputPrompt = (
    <Form.Item
      label="输入提示"
      name={["extData", "inputPrompt"]}
      rules={[{ required: true, message: "不能为空" }]}
    >
      <Input />
    </Form.Item>
  );

  const TagSelect = ({ onChange, value }: any) => {
    const onSelectChange = (v: any) => {
      onChange && onChange(v.length ? v.join(",") : undefined);
    };
    return (
      <Select
        mode="tags"
        value={value?.split(",")}
        tokenSeparators={["，", ","]}
        onChange={onSelectChange}
      />
    );
  };

  switch (mediaType) {
    case "group":
      return <></>;
    case "string":
      return <>{InputPrompt}</>;
    case "title":
      return <>{InputPrompt}</>;
    case "rich_text":
      return <>{InputPrompt}</>;
    case "picture":
      return <></>;
    case "multi_picture":
      return (
        <>
          <Form.Item
            label="最小图片数"
            name={["extData", "min"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="最大图片数"
            name={["extData", "max"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
        </>
      );
    case "slide":
      return (
        <>
          <Form.Item
            label="默认间隔"
            name={["extData", "interval"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber placeholder="单位毫秒" />
          </Form.Item>
        </>
      );
    case "audio":
      return <></>;
    case "multi_audio":
      return (
        <>
          <Form.Item
            label="最小音频数"
            name={["extData", "min"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="最大音频数"
            name={["extData", "max"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
        </>
      );
    case "video":
      return <></>;
    case "multi_video":
      return (
        <>
          <Form.Item
            label="最小视频数"
            name={["extData", "min"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="最大视频数"
            name={["extData", "max"]}
            rules={[{ required: true, message: "不能为空" }]}
          >
            <InputNumber />
          </Form.Item>
        </>
      );
    case "3D":
      return <></>;
    case "tags":
    case "tags_content":
    case "history":
    case "filed_content_ref":
      return (
        <>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label="数据来源"
                name={["extData", "systemCode"]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                rules={[{ required: true, message: "不能为空" }]}
              >
                <Select
                  allowClear
                  placeholder="请选择知识体系"
                  options={tenants.map((e: any) => ({ value: e.systemCode, label: e.systemName }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="数据类型"
                name={["extData", "source"]}
                initialValue={""}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
              >
                <Select
                  options={[
                    { value: "", label: "不限" },
                    { value: "content", label: "内容" },
                    { value: "content_cate", label: "分类" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {InputPrompt}
        </>
      );
    case "inter_link":
      return <></>;
    case "exter_link":
      return <></>;
    case "table":
      return (
        <Form.Item label="表格标题" name={["extData", "title"]}>
          <TagSelect />
        </Form.Item>
      );
    case "string_array":
    case "rich_text_array":
      return (
        <Form.Item
          label="自定义参数"
          name={["extData", "params"]}
          initialValue={"[]"}
          rules={[{ required: true, message: "不能为空" }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
        </Form.Item>
      );
  }

  return <></>;
}
