import { Menu } from 'antd';
import { ApartmentOutlined, AppstoreAddOutlined, ClusterOutlined, RobotOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { useReleaseState } from '../state/release_state';

function SideMenu() {
    const location = useLocation()
    const { identities, reloadIdentities } = useReleaseState()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="basic" title="基础">
            <Menu.Item key="/release/identity" icon={<AppstoreAddOutlined />}>
                <Link to="/release/identity">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>应用</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/release/channel" icon={<ClusterOutlined />}>
                <Link to="/release/channel">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>发行渠道</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        {
            identities?.map((identity: any) =>
                <Menu.ItemGroup key={identity.appid} title={<div style={{ display: "flex", alignItems: "center", gap: 5 }}> <img width={16} src={identity.logo} /> {identity.name}</div>}>
                    <Menu.Item key={`/release/${identity.appid}/distribution`} icon={<ApartmentOutlined />}>
                        <Link to={`/release/${identity.appid}/distribution`}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>分发 / 版本</span>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={`/release/${identity.appid}/launch-screen`} icon={<RobotOutlined />}>
                        <Link to={`/release/${identity.appid}/launch-screen`}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>开屏页</span>
                            </div>
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>)
        }
    </Menu >
}

export default SideMenu