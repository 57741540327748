import OrderedMedia from "../OrderedMedia";
import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import { useRequest } from "ahooks";
import { useGlobalState } from "../../../../components/global";

const { TextArea } = Input;

export default function HistoryInput({
                                         prefix,
                                         fullPrefix,
                                         index,
                                         form,
                                         listPathName,
                                         changePrefix,
                                         field,
                                     }: any) {
    const { extData } = field || {};
    const { currentTenant, jsonRequest } = useGlobalState();
    const item = form.getFieldValue(fullPrefix);
    const [options, setOptions]: any = useState([{ label: item?.title, value: item?.id }]);

    const handleSearch = async (keyword: string) => {
        if (!keyword) return;
        return jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: extData?.systemCode, type: "content", keyword },
        })
          .then((resp: any) => resp.json())
          .then(({ data }: any) => {
              data && setOptions(data.map((e: any) => ({
                  label: e.fullName || e.contentName,
                  value: e.id,
              })));
          });
    };

    const { run } = useRequest(handleSearch, {
        debounceWait: 500,
        manual: true,
    });

    const onSelect = ({ label, value }: any) => {
        form.setFieldValue([...fullPrefix, "title"], label);
        form.setFieldValue([...fullPrefix, "id"], value);
    };

    const onChange = () => {
        form.setFieldValue([...changePrefix.slice(0, -1), "change"], true);
    };
    
    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item name={[...prefix, "id"]} hidden initialValue={0}><Input /></Form.Item>
        <Form.Item name={[...prefix, "title"]} hidden initialValue=""><Input /></Form.Item>
        <Form.Item name={[...prefix, "ignoreSelect"]} label="书籍" initialValue={{ label: item?.title, value: item?.id }}>
            <Select
              showSearch
              onSelect={onSelect}
              placeholder={extData?.inputPrompt}
              defaultActiveFirstOption={false}
              labelInValue={true}
              suffixIcon={null}
              filterOption={false}
              onSearch={run}
              options={options}
            />
        </Form.Item>
        <Form.Item label="详情" name={[...prefix, "description"]} initialValue="">
            <TextArea autoSize={{ maxRows: 5 }} onChange={onChange} />
        </Form.Item>
    </OrderedMedia>;
}