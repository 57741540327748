import { Form, Input, Select } from "antd";
import { useState } from "react";

import { useGlobalState } from "../../../../components/global";
import { useRequest } from "ahooks";

export default function TagsInput({ field, prefix, fullPrefix, form }: any) {
    const { extData, isRequired } = field || {};
    const { currentTenant, jsonRequest } = useGlobalState();
    const [options, setOptions]: any = useState(extData?.tags?.map((tag: any) => ({
        label: `${tag.fullName || tag.name}${tag.otherStr ? `-${tag.otherStr}` : ""}`,
        value: tag.id, ...tag,
    })));

    const handleSearch = async (keyword: string) => {
        if (!keyword) return;
        return jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { systemCode: extData?.systemCode, type: extData?.source, keyword },
        })
          .then((resp: any) => resp.json())
          .then(({ data }: any) => {
              data && setOptions(data.map((e: any) => ({
                  label: `${e.fullName || e.contentName}${e.otherStr ? `-${e.otherStr}` : ""}`,
                  value: e.id,
                  ...e,
              })));
          });
    };

    const { run } = useRequest(handleSearch, {
        debounceWait: 500,
        manual: true,
    });

    const onSelect = (value: any) => {
        const tags = form.getFieldValue([...fullPrefix, "tags"]) || [];
        const extSystemCode = extData?.systemCode;
        const {
            id,
            contentName,
            systemCode,
            fullName,
            type,
            otherStr,
            sourceId,
        } = options.find((o: any) => o.id === value);
        const other = extSystemCode === "consilia" ? { doctor: otherStr } : { otherStr };
        tags.push({ id, systemCode, type, name: contentName, fullName, sourceId, ...other });
        form.setFieldValue([...fullPrefix, "tags"], tags);
    };

    const onDeselect = (value: any) => {
        const tags = form.getFieldValue([...fullPrefix, "tags"]);
        const item = tags.find((o: any) => o.id === value);
        if (!item) return;
        tags.splice(tags.indexOf(item), 1);
        form.setFieldValue([...fullPrefix, "tags"], tags);
    };

    const onClear = () => {
        form.setFieldValue([...fullPrefix, "tags"], []);
    };

    return <>
        <Form.Item name={[...prefix, "tagsSelect"]} wrapperCol={{ span: 23 }}
                   rules={[{ required: isRequired, message: "不能为空" }]}
                   initialValue={extData?.tags?.map(({ id, name }: any) => ({ label: name, value: id }))}>
            <Select
              showSearch
              onSelect={onSelect}
              onDeselect={onDeselect}
              onClear={onClear}
              mode="multiple"
              placeholder={extData?.inputPrompt}
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              onSearch={run}
              options={options}
            />
        </Form.Item>
        <Form.Item name={[...prefix, "systemCode"]} hidden></Form.Item>
        <Form.Item name={[...prefix, "source"]} hidden></Form.Item>
        <Form.Item name={[...prefix, "tags"]} hidden>
            <Input />
        </Form.Item>
    </>;
}