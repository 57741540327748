import {useEffect, useState} from "react";
import {Form, Input, InputNumber, Select} from "antd";

import {useGlobalState} from "../../../components/global";

export default function CategoryForm({form, detail, parent, tenants}: any) {
    
    const { user, navActiveKey, jsonRequest, currentTenant, signOut, setTenant } = useGlobalState()
    const [fields, setFields] = useState([])
    const isGroup = (detail.id && detail.level === 1) || (!detail.id && !parent.id);

    useEffect(() => {
        if (detail.systemCode) getFields(detail.systemCode)
        form.resetFields();
    }, []);

    const getFields = (systemCode: string) => {
        if (!systemCode) return;
        form.setFieldValue('fieldId', null)
        jsonRequest.post("/api/request", {
            path: "/v1/system-fields",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode, field_type: 'content_cate'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setFields(data)
            })
    }

    return (
        <Form form={form} initialValues={detail} size="small" name="basic"
              labelCol={{span: 8}} wrapperCol={{span: 14}}>
            <Form.Item label="ID" name="id" style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label="parentId" name="parentId" style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label="知识体系" name="systemCode" rules={[{required: true, message: '不能为空'}]}>
                <Select disabled={!!detail.id || !!parent.id} onChange={(v) => getFields(v)}
                        options={tenants.map((e: any) => ({value: e.systemCode, label: e.systemName}))}/>
            </Form.Item>
            <Form.Item label="所属字段" name="fieldId" rules={[{required: true, message: '不能为空'}]}
                       style={{display: isGroup ? "block" : "none"}}>
                <Select disabled={!!detail.id || !!parent.id}
                        options={fields.map((e: any) => ({value: e.id, label: e.fieldName}))}/>
            </Form.Item>
            {!isGroup &&
                <>
                    <Form.Item label="所属分组">{detail.groupName}</Form.Item>
                    <Form.Item label="上级分类">{detail.parentName}</Form.Item>
                </>
            }
            <Form.Item label={`${isGroup ? '分组' : '分类'}名称`} name="cateName"
                       rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label={`${isGroup ? '分组' : '分类'}简称`} name="shortCateName">
                <Input/>
            </Form.Item>
            <Form.Item label={`${isGroup ? '分组' : '分类'}编码`} name="cateCode">
                <Input/>
            </Form.Item>
            <Form.Item label="排序号" name="index" rules={[{required: true, message: '不能为空'}]}>
                <InputNumber/>
            </Form.Item>
        </Form>
    )
}