

import "./Chat.css"
import { useGlobalState } from "../components/global"
import configFunc from "../config"
import { useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
export default function Chat() {
    const { user, currentTenant } = useGlobalState()
    const [loading, setLoading] = useState(true);



    return <div className="page customer-service chat">
        {loading ? <div style={{ position: "fixed", left: "calc(50vw + 100px)", top: "calc(50vh - 60px - 64px)" }}>
            <span style={{ fontSize: 120, color: "#1677ff" }}><LoadingOutlined /></span>
        </div> : <></>}
        <iframe src={`${configFunc.HttpEndpoint("/api/customer-service/chat")}?token=${user.token}&tenant_meta=${currentTenant}`} onLoad={() => setTimeout(() => {
            setLoading(false)
        }, 1000)}>

        </iframe>
    </div >
}