import { ActionType, ProTable } from "@ant-design/pro-components";
import { Button, Flex, Form, List, Space, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef, useState } from "react";

export default function Library(params: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const tableRef = useRef<ActionType>();
    const [authSource, setAuthSource]: any = useState([]);

    useEffect(() => {
        tableRef.current?.reload();
    }, [currentTenant]);

    useEffect(() => {
        getAuthSource();
    }, []);

    const getAuthSource = async () => {

        const { data } = await jsonRequest.post("/api/request", {
            path: "/api/v1/enum",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: { type: "source" },
        }).then((resp: any) => resp.json());

        setAuthSource(data);
    };

    const columns: any[] = [
        {
            title: "用户ID",
            dataIndex: "userId",
            align: "center",
            ellipsis: true,
            width: 200,
            copyable: true,
        },
        {
            title: "账户ID",
            dataIndex: "accountId",
            align: "center",
            ellipsis: true,
            width: 200,
            copyable: true,
        },
        {
            title: "名称",
            dataIndex: "name",
            align: "center",
        },
        {
            title: "手机号",
            dataIndex: "telephone",
            align: "center",
            search: false,
        },
        {
            title: "Data",
            dataIndex: "data",
            align: "center",
            search: false,
            render: (_: any, record: any) => <span>{JSON.stringify(record.data)}</span>,
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            search: false,
            align: "center",
            width: 200,
        },
    ];

    const request = async (params: any, sort: any, filter: any) => {
        const { current, pageSize } = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/api/v1/auth/user",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {
                ...params,
                pageNumber: current,
                pageSize,
            },
        }).then((resp: any) => resp.json());
        const { data } = result;
        return { data: data.list, success: result.code === 0, total: data.total };
    };

    return <>
        <h1>用户库</h1>
        <ProTable
          rowKey="userId"
          actionRef={tableRef}
          cardBordered
          columns={columns}
          expandable={{
              rowExpandable: (record: any) => record.authList?.length > 0,
              expandedRowRender: (record: any) =>
                <List size="small"
                      dataSource={record.authList}
                      renderItem={(item: any) =>
                        <Flex justify="space-around">
                            <span>ID：{item.id}</span>
                            <span>授权来源：{authSource.find((i: any) => i.enumKey === item.source)?.enumName || item.source}</span>
                            <span>第三方标识：{item.identifier}</span>
                            <span>授权时间：{item.createTime}</span>
                        </Flex>
                      }
                />,
          }}
          request={request}
          pagination={{ defaultPageSize: 10, showSizeChanger: true }}
          search={{ span: 6, defaultCollapsed: false }}
          toolBarRender={() => [<Button
            key="button"
            icon={<PlusOutlined />}
            // onClick={() => handleAddOrEdit({})}
            type="primary"
          >
              新增
          </Button>]}
        />
    </>;
}