import { Button, Divider, Form, Modal, Table } from "antd"
import { useReleaseState } from "../state/release_state"
import { ChannelForm } from "./Form";
import { useGlobalState } from "../../components/global";
import { ClusterOutlined, PlusCircleTwoTone } from "@ant-design/icons";

export default function ReleaseChannel(params: any) {
    const { jsonRequest } = useGlobalState()
    const { channels, reloadChannel } = useReleaseState()
    const [form] = Form.useForm()

    const handleFormModal = () => {
        Modal.confirm({
            title: '应用表单',
            content: <ChannelForm form={form} />,
            onOk: (close: any) => {
                form
                    .validateFields()
                    .then((values: any) => {
                        jsonRequest.application("post", "zy:services::release", "/api/channel", values)
                            .then(() => {
                                reloadChannel();
                                form.resetFields();
                                close();
                            })
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    return <div className="page release-channel">
        <h1><ClusterOutlined /> 发行渠道 <Divider type="vertical" />
            <Button type="link" icon={<PlusCircleTwoTone />} onClick={handleFormModal}>添加渠道</Button></h1>
        <div className="content">
            <Table dataSource={channels!}>
                <Table.Column title="Key" dataIndex="key" key="key"></Table.Column>
                <Table.Column title="渠道名" dataIndex="name" key="name"></Table.Column>
                <Table.Column title="平台" dataIndex="platform" key="platform"></Table.Column>
                <Table.Column title="描述" dataIndex="remark" key="remark"></Table.Column>
            </Table>
        </div>
    </div>
}