import { Button, Collapse, Form, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import GlobalWrap from "../../../../../components/globalWrap";
import ViewPointForm from "./ViewPointForm";
import dayjs from "dayjs";

export default function ViewPointTable({ value, name, form, fullPrefix }: any) {
    const [pointForm] = Form.useForm();

    const exchangeIndex = (index1: any, index2: any) => {
        const list = form.getFieldValue(name);
        const node1 = list[index1];
        list[index1] = list[index2];
        list[index2] = node1;
        // 重设index
        list.forEach((e: any, i: any) => e.index = i + 1);
        form.setFieldValue(name, list);
        form.validateFields(name, { validateOnly: true });
        onViewPointChange();
    };
    
    const onViewPointChange = () => {
        form.setFieldValue([...fullPrefix.slice(0, -1), 'viewPointChange'], true);
    }

    const deleteItem = (index: any) => {
        const list = form.getFieldValue(name);
        list.splice(index, 1);
        // 重设index
        list.forEach((e: any, i: any) => e.index = i + 1);
        form.setFieldValue(name, list);
        form.validateFields(name, { validateOnly: true });
        onViewPointChange();
    };

    const handleAddOrDetail = (detail: any) => {
        Modal.confirm({
            width: 600,
            title: "新增看点",
            content: <GlobalWrap><ViewPointForm form={pointForm} detail={detail} /></GlobalWrap>,
            onOk: (close) => {
                pointForm.validateFields()
                  .then(({ time, ...others }: any) => {
                      const list = form.getFieldValue(name);
                      const startOfDay = time.startOf("day");
                      const second = time.diff(startOfDay, "second");
                      const newData = { ...others, time: second };
                      if (detail.id) {
                          const item = list.find((e: any) => e.index === others.index);
                          list[list.indexOf(item)] = newData;
                      } else {
                          list.push(newData);
                      }
                      form.setFieldValue(name, list);
                      form.validateFields(name, { validateOnly: true });
                      onViewPointChange();
                      close();
                  })
                  .catch((e) => {
                      console.error(e);
                  });
                return false;
            },
            afterClose: () => pointForm.resetFields(),
        });
    };

    const columns: any = [
        {
            title: "排序号",
            dataIndex: "index",
            key: "index",
            align: "center",
        },
        {
            title: "时间",
            dataIndex: "time",
            key: "time",
            align: "center",
            render: (_: any, record: any) => <span>{dayjs.unix(record.time).utc().format("HH:mm:ss")}</span>,
        },
        {
            title: "内容",
            dataIndex: "content",
            key: "content",
            align: "center",
            width: "40%",
        },
        {
            title: "操作",
            key: "operation",
            align: "center",
            render: (_: any, record: any, index: number) => <>
                <Button type="link" onClick={() => exchangeIndex(index, index - 1)}
                        disabled={index === 0}>上移</Button>
                <Button type="link" onClick={() => exchangeIndex(index, index + 1)}
                        disabled={value.length - 1 === index}>下移</Button>
                <Button type="link" onClick={() => handleAddOrDetail(record)}>修改</Button>
                <Popconfirm title="确定删除吗？" onConfirm={() => deleteItem(index)}>
                    <Button type="link" danger>删除</Button>
                </Popconfirm>
            </>,
        },
    ];

    return <Collapse defaultActiveKey={[0]} items={[{
        key: 0,
        label: "看点",
        children: <Table columns={columns} dataSource={value} />,
        extra: <Space>
            <Tooltip title="新增看点">
                <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handleAddOrDetail({ index: value.length + 1 });
                        }} />
            </Tooltip>
        </Space>,
    }]} />;
}