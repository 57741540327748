import { Button, Form, Input, message, Space } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { bytesToSize } from "../../../../../components/utils";
import React, { useState } from "react";

import { useGlobalState } from "../../../../../components/global";
import OssPath from "../../../../../components/oss_path";

export default function VideoInfo({ value, form, name, prefix }: any) {
    const { currentTenant, jsonRequest } = useGlobalState();

    const videoName = form.getFieldValue([...name, "videoName"]);
    const size = form.getFieldValue([...name, "size"]);
    const definition = form.getFieldValue([...name, "definition"]);
    const definitionValue = form.getFieldValue([...name, "definitionValue"]);
    const realDefinitionValue = form.getFieldValue([...name, "realDefinitionValue"]);
    const duration = form.getFieldValue([...name, "duration"]);
    const audioPath = form.getFieldValue([...name, "audioPath"]);
    const previewPictures = form.getFieldValue([...name, "previewPictures"]);

    const [loading1, setLoading1]: any = useState(false);
    const [loading2, setLoading2]: any = useState(false);

    const createAudio = async () => {
        if (!value) return;
        const objectKey = value.substring(value.indexOf("objectKey=") + 10);
        setLoading1(true);
        try {
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/aliyun/create/audio`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { objectKey },
            }).then((resp: any) => resp.json());
            const ossPath = await OssPath(jsonRequest, currentTenant);
            form.setFieldValue([...name, "audioPath"], `${ossPath}${data}`);
            message.success("音频生成成功");
        } catch (e) {
            message.error("音频生成失败");
            console.error(e);
        }
        setLoading1(false);
    };

    const createPictures = async () => {
        if (!value) return;
        const objectKey = value.substring(value.indexOf("objectKey=") + 10);
        setLoading2(true);
        try {
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/aliyun/create/pictures`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { objectKey, duration },
            }).then((resp: any) => resp.json());
            const ossPath = await OssPath(jsonRequest, currentTenant);
            const result = data?.map((item: any) => (`${ossPath}${item}`));
            form.setFieldValue([...name, "previewPictures"], result);
            message.success("视频截图生成成功");
            form.setFieldValue([...name.slice(0, -1), "previewPicturesChange"], true);
        } catch (e) {
            message.error("视频截图生成失败");
            console.error(e);
        }
        setLoading2(false);
    };

    return !value ? <span>无</span> : <Space direction="vertical">
        <Form.Item name={[...prefix.slice(0, -1), "previewPicturesChange"]} initialValue={false} hidden><Input /></Form.Item>
        <Button
          onClick={() => window.open(value, "_blank")}
          icon={<LinkOutlined />} type="link">{videoName}</Button>
        <Space>
            <span>大小 [{bytesToSize(size)}]</span>
            <span>分辨率 [{definition}({definitionValue})]</span>
            <span>真实分辨率 [{realDefinitionValue}]</span>
        </Space>
        <Space>
            <Button type={previewPictures?.length > 0 ? "default" : "primary"} loading={loading2}
                    onClick={createPictures}>{`${previewPictures?.length > 0 ? "重新生成" : "生成"}视频切图`}</Button>
            <Button type={audioPath ? "default" : "primary"} loading={loading1} onClick={createAudio}>
                {`${audioPath ? "重新生成" : "生成"}生成音频`}</Button>
        </Space>
    </Space>;
}