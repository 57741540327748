import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";

function SideMenu() {
    const { jsonRequest, currentTenant } = useGlobalState()
    const location = useLocation()
    const [tenants, setTenants] = useState<any>([]);

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    useEffect(() => {
        getTenants();
    }, [currentTenant]);

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({ data }) => {
                setTenants(data)
            })
    }

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline">
        <Menu.ItemGroup key="/knowledge/keep" title="知识库维护">
            <Menu.Item key="/knowledge/keep/system" icon={<UnorderedListOutlined />}>
                <Link to="/knowledge/keep/system">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>知识体系维护</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/knowledge/keep/field" icon={<UnorderedListOutlined />}>
                <Link to="/knowledge/keep/field">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>字段维护</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/knowledge/keep/category" icon={<UnorderedListOutlined />}>
                <Link to="/knowledge/keep/category">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>分类维护</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/knowledge/keep/template" icon={<UnorderedListOutlined />}>
                <Link to="/knowledge/keep/template">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>输入模板维护</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="order" title="内容维护">
            <Menu.Item key="/knowledge/content/" icon={<UnorderedListOutlined />}>
                <Link to="/knowledge/content/">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>全部内容</span>
                    </div>
                </Link>
            </Menu.Item>
            {tenants?.map((e: any) => <Menu.Item key={`/knowledge/content/${e.systemCode}`} icon={<UnorderedListOutlined />}>
                <Link to={`/knowledge/content/${e.systemCode}`}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{e.systemName}</span>
                    </div>
                </Link>
            </Menu.Item>)}
        </Menu.ItemGroup>
    </Menu>
}


export default SideMenu