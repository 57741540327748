import { create } from "zustand"
import { globalState, useGlobalState } from "../../components/global"

export type ReleaseState = {
    // 应用列表
    identities: Array<any> | null,
    // 渠道列表
    channels: Array<any> | null,
    reloadIdentities: (state?: globalState | null) => void,
    reloadChannel: (state?: globalState | null) => void
}

export const useReleaseState = create<ReleaseState>((set, get) => {
    // 当租户发生变更时调用
    useGlobalState.subscribe((state) => {
        get().reloadIdentities(state)
        get().reloadChannel(state)
    })

    return {
        systemPlatforms: null,
        identities: null,
        channels: null,
        distributions: null,
        reloadIdentities: (state = null) => {
            // 初始化的时候获取应用
            (state ?? useGlobalState.getState()).jsonRequest.application("get", "zy:services::release", "/api/identity", {})
                .then((resp) => set({ identities: resp.body.data }))
        },
        reloadChannel: (state = null) => {
            // 初始化的时候获取应用
            (state ?? useGlobalState.getState()).jsonRequest.application("get", "zy:services::release", "/api/channel", {})
                .then((resp) => set({ channels: resp.body.data }))
        }
    }
})