import { UnorderedListOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

export default function SideMenu() {
  const location = useLocation();

  const pathName = location.pathname.split("/").slice(0, 4).join("/");
  const fullPathName = location.pathname;

  return (
    <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline">
      {/*<Menu.Item key="shoppe" icon={<UnorderedListOutlined />}>*/}
      {/*  <Link to="/shoppe">*/}
      {/*    <div style={{ display: "flex", justifyContent: "space-between" }}>*/}
      {/*      <span>主页</span>*/}
      {/*    </div>*/}
      {/*  </Link>*/}
      {/*</Menu.Item>*/}

      <Menu.ItemGroup key="user" title="统计">
        <Menu.Item key="/shoppe/user/statistics" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/user/statistics">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>用户统计</span>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="/shoppe/product/statistics" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/product/statistics">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>商品统计</span>
            </div>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/shoppe/user" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/user">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>用户管理</span>
            </div>
          </Link>
        </Menu.Item> */}
      </Menu.ItemGroup>

      <Menu.ItemGroup key="product" title="商品">
        <Menu.Item key="/shoppe/product/manage" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/product/manage">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>商品管理</span>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="/shoppe/product/classify" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/product/classify">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>商品分类</span>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="/shoppe/product/specification" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/product/specification">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>商品规格</span>
            </div>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="order" title="订单">
        <Menu.Item key="/shoppe/order" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/order">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>订单管理</span>
            </div>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="finance" title="财务">
        <Menu.Item key="/shoppe/finance/transaction" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/finance/transaction">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>资金流水</span>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="/shoppe/finance/coin" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/finance/coin">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>货币</span>
            </div>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="decorate" title="装修">
        <Menu.Item key="/shoppe/decorate/carousel" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/decorate/carousel">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>商品轮播图</span>
            </div>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="setting" title="设置">
        <Menu.Item key="/shoppe/setting/channel" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/setting/channel">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>发货渠道</span>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="/shoppe/setting/company" icon={<UnorderedListOutlined />}>
          <Link to="/shoppe/setting/company">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>厂家管理</span>
            </div>
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
      {/* </RequireRolesOr> */}
    </Menu>
  );
}
