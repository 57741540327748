import { ConfigProvider } from "antd";
import zhCN from 'antd/lib/locale/zh_CN';
import React from "react";

export default function GlobalWrap({ children, ...props }: any) {
    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, props)
    })

    return <ConfigProvider locale={zhCN}>
            {childrenWithProps}
    </ConfigProvider>
}