import { Anchor, Form } from "antd";
import "./ContentAnchor.css";

const getMediaAnchorKey = ({ mediaId, mediaName }: any) => {
    const name = mediaName?.replace("(", "_").replace(")", "_");
    return `content-media-${mediaId}-${name}`;
};

export { getMediaAnchorKey };

export default function ContentAnchor({ form }: any) {

    const mediasToItems = (medias: any) => {
        const items: any = medias?.map(({ mediaId, mediaName, sons }: any) => {
            const key = getMediaAnchorKey({ mediaId, mediaName });
            const item: any = { key, href: `#${key}`, title: mediaName, test: "test" };
            const children = mediasToItems(sons);
            return { ...item, children };
        });
        return items;
    };

    const getItems = () => {
        const { contentName, medias } = form.getFieldsValue();
        const children = mediasToItems(medias);
        const items: any = [{ key: 1, href: "#base-info", title: contentName || "基本信息", children }];
        return items;
    };

    const getFullAnchorPath = (medias: any, target: string, currentPath: any = [], result: any = []) => {
        for (let i = 0; i < medias.length; i++) {
            const { mediaId, mediaName, sons } = medias[i];
            const key = `#${getMediaAnchorKey({ mediaId, mediaName })}`;
            const newPath = [...currentPath, key];
            if (sons && sons.length > 0) {
                getFullAnchorPath(sons, target, newPath, result);
            }
            if (key === target) {
                result.push(...newPath);
            }
        }
        return result;
    };

    const dispatchAnchorEvent = async (target: any) => {
        const { medias } = form.getFieldsValue();
        const path: any = getFullAnchorPath(medias, target);
        for (let i = 0; i < path.length; i++) {
            window.dispatchEvent(new Event(`content-anchor-click:${path[i]}`));
            await new Promise(resolve => setTimeout(resolve, 200));
        }
    };

    const onAnchorClick = (e: any, link: any) => {
        // 触发高亮动画
        const element = document.querySelector(link.href);
        if (element) {
            // 添加闪烁动画类名
            element.classList.add("anchor-highlight");

            // 设置定时器，移除类名
            setTimeout(() => {
                element.classList.remove("anchor-highlight");
            }, 2000);
        }
        // 展开Cllapse
        dispatchAnchorEvent(link.href);
    };

    function getContainer(): any {
        // @ts-ignore
        return document.querySelector("#content-form");
    }

    return <Form.Item shouldUpdate className="content-anchor-box">
        {() => {
            const items = getItems();
            return <Anchor getContainer={getContainer} items={items} affix={false} onClick={onAnchorClick} />;
        }}
    </Form.Item>;
}