import { useEffect } from "react"
import { useGlobalState } from "../../components/global"
import { useReleaseState } from "../state/release_state"
import { Button, Card, Divider, Flex, Form, Image, Modal, Row } from "antd"
import { IdentityForm } from "./Form"
import { ApartmentOutlined, AppstoreAddOutlined, PlusCircleTwoTone, RobotOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

export function Identity() {
    const { jsonRequest } = useGlobalState()
    const { identities, reloadIdentities } = useReleaseState()
    const [form] = Form.useForm()

    const handleFormModal = () => {
        Modal.confirm({
            title: '应用表单',
            content: <IdentityForm form={form} />,
            onOk: (close: any) => {
                form
                    .validateFields()
                    .then((values: any) => {
                        jsonRequest.application("post", "zy:services::release", "/api/identity", values)
                            .then(() => {
                                reloadIdentities();
                                form.resetFields();
                                close();
                            })
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    return <div className="page release-identity">
        <h1><AppstoreAddOutlined /> 应用列表 <Divider type="vertical" />
            <Button type="link" icon={<PlusCircleTwoTone />} onClick={handleFormModal}>创建应用</Button></h1>
        <div className="content">
            <Flex wrap="wrap" gap="small">
                {identities?.map((identity: any) => {
                    return <Card key={identity.appid}
                        actions={[<Link to={`/release/${identity.appid}/distribution`}><ApartmentOutlined /> 分发 / 版本</Link>, <Link to={`/release/${identity.appid}/launch-screen`} ><RobotOutlined /> 开屏页</Link>]}
                        cover={<div style={{ display: "flex", margin: "20px 0", justifyContent: "center", alignItems: 'center' }}><Image height={200} width={200} src={identity.logo} /></div>}>
                        <Card.Meta title={identity.name} description={identity.description} />
                        <div>{identity.appid} <Divider type="vertical" /> {identity.platform}</div>
                    </Card>
                })}
            </Flex>
        </div>
    </div>
}