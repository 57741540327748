import { Outlet } from "react-router-dom"
import SideMenu from "./components/side_menu"
import { useGlobalState } from "../components/global"
import { useEffect } from "react"


function Mall(params: any) {
    const { navActive } = useGlobalState()

    useEffect(() => {
        navActive("MALL")

    }, [])

    return <div className="module module-mall">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>

}

export default Mall